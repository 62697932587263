<h4>{{ "i18n.page.dashboard.title" | translate }}</h4>
<div class="ob-grid">
	<div class="ob-span-3">
		<mat-card appearance="outlined">
			<mat-card-header>
				<mat-card-title>{{ "i18n.page.dashboard.card.usertasks.title" | translate }}</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<div class="card-content-container">
					<mat-card-title class="card-title-subtitle">{{ "i18n.page.dashboard.subtitle.usertasks.description" | translate }}</mat-card-title>
					<div *ngFor="let item of catalogUserPenndingLevels">
						<a
							*ngIf="item?.reference as reference"
							class="mat-chip-section link-as-text"
							[routerLink]="['/catalog']"
							[queryParams]="{ publisher: publishersIdendifier, levelProposals: item?.reference }"
						>
							<span>{{ "i18n.page.proposed.as.title" | translate }} {{ getPublicationLevelKey(reference) | translate }}</span>
							<mat-chip-set class="mat-chip-counter">
								<mat-chip class="info">{{ item?.count }}</mat-chip>
							</mat-chip-set>
						</a>
					</div>

					<div *ngFor="let item of catalogUserPenndingStatuses">
						<a
							*ngIf="item?.reference as reference"
							class="mat-chip-section link-as-text"
							[routerLink]="['/catalog']"
							[queryParams]="{ publisher: publishersIdendifier, statusProposals: item?.reference, publicationLevels: getLevelFilter() }"
						>
							<span>{{ "i18n.page.proposed.as.title" | translate }} {{ getRegistrationStatusKey(reference) | translate }}</span>
							<mat-chip-set class="mat-chip-counter">
								<mat-chip class="info">{{ item?.count }}</mat-chip>
							</mat-chip-set>
						</a>
					</div>
				</div>
			</mat-card-content>
		</mat-card>
	</div>

	<div class="ob-span-3">
		<mat-card appearance="outlined">
			<mat-card-header>
				<mat-card-title>{{ "i18n.page.dashboard.card.proposals.title" | translate }}</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<div class="card-content-container">
					<mat-card-title class="card-title-subtitle">{{ "i18n.page.dashboard.subtitle.proposals.description" | translate }}</mat-card-title>
					<div *ngFor="let item of catalogAgencyPenndingLevels">
						<a
							*ngIf="item?.reference as reference"
							class="mat-chip-section link-as-text"
							[routerLink]="['/catalog']"
							[queryParams]="{ publisher: publishersIdendifier, levelProposals: item?.reference }"
						>
							<span>{{ "i18n.page.proposed.as.title" | translate }} {{ getPublicationLevelKey(reference) | translate }}</span>
							<mat-chip-set class="mat-chip-counter">
								<mat-chip class="info">{{ item?.count }}</mat-chip>
							</mat-chip-set>
						</a>
					</div>
					<div *ngFor="let item of catalogAgencyPenndingStatuses">
						<a
							*ngIf="item?.reference as reference"
							class="mat-chip-section link-as-text"
							[routerLink]="['/catalog']"
							[queryParams]="{ publisher: publishersIdendifier, statusProposals: item?.reference }"
						>
							<span>{{ "i18n.page.proposed.as.title" | translate }} {{ getRegistrationStatusKey(reference) | translate }}</span>
							<mat-chip-set class="mat-chip-counter">
								<mat-chip class="info">{{ item?.count }}</mat-chip>
							</mat-chip-set>
						</a>
					</div>
				</div>
			</mat-card-content>
		</mat-card>
	</div>

	<div class="ob-span-3">
		<mat-card appearance="outlined">
			<mat-card-header>
				<mat-card-title>{{ "i18n.page.dashboard.card.agencydata.title" | translate }}</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<div class="card-content-container">
					<mat-card-title class="card-title-subtitle">{{ "i18n.page.dashboard.subtitle.agencydata.description" | translate }}</mat-card-title>
					<div *ngFor="let item of catalogAgencyData">
						<a
							*ngIf="item?.reference as reference"
							class="mat-chip-section link-as-text"
							[routerLink]="['/catalog']"
							[queryParams]="{ publisher: publishersIdendifier, types: item?.reference }"
						>
							<span>{{ "i18n.page.dashboard.card.agencydata.type." + reference.replace(" ", "").toLowerCase() | translate }}</span>
							<mat-chip-set class="mat-chip-counter">
								<mat-chip class="info">{{ item?.count }}</mat-chip>
							</mat-chip-set>
						</a>
					</div>
				</div>
			</mat-card-content>
		</mat-card>
	</div>
</div>
