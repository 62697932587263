<div class="flex-container container">
	<div class="last">
		<h1>{{ "i18n.page.catalog.title" | translate }}</h1>
	</div>
	<div class="button-wrapper">
		<button
			id="catalog-menubutton"
			mat-button
			obButton="secondary"
			#buttonMenuTrigger="matMenuTrigger"
			[matMenuTriggerFor]="buttonMenu"
			[disabled]="(cannotCreateDataset$ | async) && (cannotCreateDataSercive$ | async) && (cannotCreatePubicService$ | async)"
		>
			{{ "i18n.page.catalog.menubutton" | translate }}
			<mat-icon svgIcon="{{ buttonMenuTrigger.menuOpen ? 'chevron-up' : 'chevron-down' }}"></mat-icon>
		</button>
		<mat-menu #buttonMenu="matMenu">
			<a id="create-dataset" mat-button [routerLink]="['../datasets', nav_value_create]" [disabled]="cannotCreateDataset$ | async">
				{{ "i18n.page.catalog.button.create.dataset" | translate }}
			</a>
			<a id="create-dataservice" mat-button [routerLink]="['../dataservices', nav_value_create]" [disabled]="cannotCreateDataSercive$ | async">
				{{ "i18n.page.catalog.button.create.dataservice" | translate }}
			</a>
			<a id="create-publicservice" mat-button [routerLink]="['../publicservices', nav_value_create]" [disabled]="cannotCreatePubicService$ | async">
				{{ "i18n.page.catalog.button.create.publicservice" | translate }}
			</a>
			<a id="create-concept" mat-button [routerLink]="['../concepts', nav_value_create]" [disabled]="cannotCreatePubicService$ | async">
				{{ "i18n.page.catalog.button.create.concept" | translate }}
			</a>
		</mat-menu>
	</div>
</div>

<div class="ob-grid search-section">
	<div class="ob-span-6 search-input-wrapper">
		<mat-form-field>
			<div class="search-input">
				<input
					id="catalog-search"
					type="search"
					name="query"
					matInput
					placeholder="{{ 'i18n.page.catalog.search' | translate }}"
					[(ngModel)]="queryInput"
					(keyup.enter)="search()"
				/>
				<button mat-icon-button [obButton]="'tertiary'" (click)="onResetQuery()">
					<mat-icon *ngIf="queryInput" svgIcon="cancel" class="ob-icon"></mat-icon>
				</button>
				<button mat-icon-button [obButton]="'tertiary'" (click)="search()">
					<mat-icon svgIcon="search" class="ob-icon"></mat-icon>
				</button>
			</div>
		</mat-form-field>
	</div>
</div>

<div class="ob-grid">
	<div class="ob-span-12">
		<nav mat-tab-nav-bar [tabPanel]="tabPanel" mat-stretch-tabs="false">
			<a mat-tab-link routerLink="/catalog/all" [queryParams]="getQueryParams(searchTypeEnum.All)" routerLinkActive="active" [id]="'catalog-tabs-all'">
				{{ "i18n.catalog.tabs.all" | translate }} ({{ allCount }})
			</a>
			<a
				mat-tab-link
				routerLink="/catalog/datasets"
				[queryParams]="getQueryParams(searchTypeEnum.Dataset)"
				routerLinkActive="active"
				[id]="'catalog-tabs-datasets'"
			>
				{{ "i18n.catalog.tabs.datasets" | translate }} ({{ getCountResult(catalogType.Dataset) }})
			</a>
			<a
				mat-tab-link
				routerLink="/catalog/publicservices"
				[queryParams]="getQueryParams(searchTypeEnum.Publicservice)"
				routerLinkActive="active"
				[id]="'catalog-tabs-publicservices'"
			>
				{{ "i18n.catalog.tabs.publicservices" | translate }} ({{ getCountResult(catalogType.PublicService) }})
			</a>
			<a
				mat-tab-link
				routerLink="/catalog/dataservices"
				[queryParams]="getQueryParams(searchTypeEnum.Dataservice)"
				routerLinkActive="active"
				[id]="'catalog-tabs-dataservices'"
			>
				{{ "i18n.catalog.tabs.dataservices" | translate }} ({{ getCountResult(catalogType.DataService) }})
			</a>
			<a
				mat-tab-link
				routerLink="/catalog/concepts"
				[queryParams]="getQueryParams(searchTypeEnum.Concept)"
				routerLinkActive="active"
				[id]="'catalog-tabs-concepts'"
			>
				{{ "i18n.catalog.tabs.concepts" | translate }} ({{ getCountResult(catalogType.Concept) }})
			</a>
			<div mat-tab-link class="layoutfix"></div>
		</nav>
		<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
	</div>
</div>

<div class="filter-section margin-top-lg">
	<app-search-filters
		[hideFilters]="hideFilters"
		[searchType]="searchType"
		[updateFilter]="updateFilterSubject.asObservable()"
		(countResultChange)="onCountResultChange($event)"
	>
	</app-search-filters>
</div>

<app-table [dto]="catalogEntry"></app-table>

<mat-paginator
	[length]="pagingInfo.totalRows"
	[pageSizeOptions]="pagingInfo.pageSizeOptions"
	[pageSize]="pagingInfo.pageSize"
	[pageIndex]="pagingInfo.page - 1"
	(page)="onChangePage($event)"
></mat-paginator>
