import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {PublicationLevelView, RegistrationStatusView} from '@bfs-sis/bfs-iop-admin-web-api-client';
import {Observable, of} from 'rxjs';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {AppConfig} from '../../app.config';
import {IAppConfig} from '../../app.config.interface';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {MatDialog} from '@angular/material/dialog';
import {DialogComponent, DialogType} from '../dialog/dialog.component';
import {SHARED_DIALOG_CANCEL_BUTTON_KEY, SHARED_DIALOG_LOCK_BUTTON_KEY} from 'src/app/app-constants';

@Component({
	selector: 'app-status',
	templateUrl: './status.component.html',
	styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnChanges {
	@Input() version: string | undefined;
	@Input() enableVersioning: boolean = false;
	@Input() disbaleNewVersion$: Observable<boolean> = of(true);
	@Input() registrationStatus: RegistrationStatusView | undefined;
	@Input() publicationLevel: PublicationLevelView | undefined;
	@Input() identifier: string | undefined;
	@Input() showWarning: boolean = false;
	@Input() warningText: string = '';
	@Input() showInfo: boolean = false;
	@Input() infoText: string = '';
	@Input() isLocked: boolean = false;
	@Input() enableLock: boolean = false;
	@Input() disableLock: boolean = true;
	@Input() lockDialogHeaderKey = '';
	@Input() lockDialogTextKey = '';
	@Output() setRegistrationStatus: EventEmitter<string> = new EventEmitter();
	@Output() proposeRegistrationStatus: EventEmitter<string> = new EventEmitter();
	@Output() setPublicationLevel: EventEmitter<string> = new EventEmitter();
	@Output() proposePublicationLevel: EventEmitter<string> = new EventEmitter();
	@Output() lock: EventEmitter<boolean> = new EventEmitter();
	currentLanguage: string;
	combinedStatus: string[] = [];
	combinedLevel: string[] = [];
	menuItemsPublicationLevel = [{text: 'i18n.shared.status.accept', icon: 'checkmark', action: false, disabled: false}];
	menuItemsRegistrationStatus = [
		{text: 'i18n.shared.status.accept', icon: 'checkmark', action: false, disabled: !this.isStatusProposalAllowed()},
		{text: 'i18n.shared.status.decline', icon: 'repeat', action: true, disabled: false}
	];

	constructor(private readonly translate: TranslateService, private readonly route: ActivatedRoute, private readonly dialog: MatDialog) {
		this.currentLanguage = this.translate.currentLang;
		this.translate.onLangChange.subscribe((language: LangChangeEvent) => (this.currentLanguage = language.lang));
	}

	ngOnChanges(changes: SimpleChanges) {
		const change = changes.registrationStatus;
		const changePublicationLevel = changes.publicationLevel;
		if (change.currentValue) {
			this.combinedStatus = this.mergeArrays(this.registrationStatus?.allowedProposals ?? [], this.registrationStatus?.allowedStatuses ?? []);
			this.combinedLevel = this.mergeArrays(this.publicationLevel?.allowedProposals ?? [], this.publicationLevel?.allowedLevels ?? []);
		}
	}

	isStatusAllowed(entry: string): boolean | undefined {
		if (this.registrationStatus?.allowedStatuses) {
			return this.registrationStatus?.allowedStatuses.includes(entry);
		}
		return undefined;
	}

	isStatusProposalAllowed(): boolean | undefined {
		return this.registrationStatus?.allowedStatuses?.includes(<string>this.registrationStatus?.proposal);
	}

	onSetRegistrationStatusClick(status: string) {
		this.setRegistrationStatus.emit(status);
	}

	onProposeRegistrationStatusClick(proposal: string) {
		this.proposeRegistrationStatus.emit(proposal);
	}

	onProposeRegistrationStatusCancel() {
		this.proposeRegistrationStatus.emit('');
	}

	onSetPublicationLevelClick(level: string) {
		this.setPublicationLevel.emit(level);
	}

	onProposePublicationLevelClick(proposal: string) {
		this.proposePublicationLevel.emit(proposal);
	}

	isSetLevelAllowed(): boolean | undefined {
		return this.publicationLevel?.allowedLevels?.includes(<string>this.publicationLevel?.proposal);
	}

	onChangeLockToggle(event: MatSlideToggleChange) {
		// eslint-disable-next-line max-len
		this.translate.get([this.lockDialogHeaderKey, this.lockDialogTextKey, SHARED_DIALOG_CANCEL_BUTTON_KEY, SHARED_DIALOG_LOCK_BUTTON_KEY]).subscribe(result => {
			const dialogRef = this.dialog.open(DialogComponent, {
				data: {
					showHeader: true,
					headerText: result[this.lockDialogHeaderKey],
					bodyText: result[this.lockDialogTextKey],
					dialogType: DialogType.confirm,
					cancelButtonText: result[SHARED_DIALOG_CANCEL_BUTTON_KEY],
					confirmButtonText: result[SHARED_DIALOG_LOCK_BUTTON_KEY]
				}
			});
			const dialogCancel = dialogRef.componentInstance.cancel.subscribe(() => {
				this.isLocked = !event.checked;
			});
			const dialogConfirm = dialogRef.componentInstance.confirm.subscribe(() => {
				this.lock.emit(event.checked);
			});
			dialogRef.afterClosed().subscribe(() => {
				dialogCancel.unsubscribe();
				dialogConfirm.unsubscribe();
			});
		});
	}

	redirectToExternalResource(): string {
		let concepts = this.route?.parent?.snapshot?.url[1]?.path === 'concepts';
		let datasets = this.route?.parent?.snapshot?.url[1]?.path === 'datasets';
		let dataservices = this.route?.parent?.snapshot?.url[1]?.path === 'dataservices';
		let publicservices = this.route?.parent?.snapshot?.url[1]?.path === 'publicservices';

		const id = this.route.snapshot.params.id || this.route.snapshot.params.conceptId;
		const i14yPublicRoute = AppConfig.getConfig<IAppConfig>().I14Y_PUBLIC_ROUTE;

		const routes = {
			concepts: `${i14yPublicRoute}/${this.currentLanguage}/concepts/${id}/description`,
			dataservices: `${i14yPublicRoute}/${this.currentLanguage}/catalog/dataservices/${id}`,
			publicservices: `${i14yPublicRoute}/${this.currentLanguage}/catalog/publicservices/${this.identifier}/description`,
			datasets: `${i14yPublicRoute}/${this.currentLanguage}/catalog/datasets/${this.identifier}/description`
		};

		if (concepts) {
			return routes.concepts;
		}
		if (dataservices) {
			return routes.dataservices;
		}
		if (publicservices) {
			return routes.publicservices;
		}
		if (datasets) {
			return routes.datasets;
		}
		return '';
	}

	showDropdownButton(menuEntries: string[] | undefined): boolean {
		return menuEntries ? menuEntries.length > 0 : false;
	}

	private mergeArrays(arr1: string[], arr2: string[]): string[] {
		return Array.from(new Set([...arr1, ...arr2]));
	}
}
