import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'registrationstatusclass'})
export class RegistrationStatusClassPipe implements PipeTransform {
	transform(source: string | undefined): string {
		switch (source) {
			case 'Recorded':
			case 'Qualified':
			case 'Standard':
			case 'Preferred Standard':
				return 'success';
			case 'Superseded':
			case 'Retired':
				return 'warning';
			case 'Initial':
			case 'Candidate':
			default:
				return 'info';
		}
	}
}
