import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {NAV_VALUE_CREATE} from '../app-constants';
import {
	CatalogClient,
	CatalogEntry,
	CatalogType,
	ConceptType,
	FilterCountResult,
	FilterCountResultItem,
	SwaggerResponse
} from '@bfs-sis/bfs-iop-admin-web-api-client';
import {SearchResultPagingInfo} from '../shared/searchResultPagingInfo';
import {PageEvent} from '@angular/material/paginator';
import {filter, map, Observable, of, startWith, Subject, takeUntil} from 'rxjs';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {AllowActionService} from '../services/allow.action.service';
import {SearchFilters, SearchType} from '../shared/search-filters/search-filters';
import {SearchFilterService} from '../shared/search-filters/search-filters.service';

@Component({
	selector: 'app-catalog',
	templateUrl: './catalog.component.html',
	styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements AfterViewInit, OnInit, OnDestroy {
	readonly nav_value_create: string = NAV_VALUE_CREATE;
	hideFilters: boolean = true;
	queryInput: string = this.route.snapshot.queryParamMap.get('query') ?? '';
	query: string | undefined;
	catalogEntry: CatalogEntry[] = [];
	cannotCreateDataset$: Observable<boolean> = of(true);
	cannotCreateDataSercive$: Observable<boolean> = of(true);
	cannotCreatePubicService$: Observable<boolean> = of(true);
	defaultPageSize: number = 50;
	defaultPage: number = 1;
	pagingInfo: SearchResultPagingInfo = new SearchResultPagingInfo(undefined, this.defaultPageSize);
	allCount: number = 0;
	countResults: FilterCountResultItem[] | undefined;
	catalogType = CatalogType;
	searchTypeEnum = SearchType;

	updateFilterSubject: Subject<void> = new Subject<void>();

	private readonly unsubscribe$ = new Subject();

	constructor(
		private readonly catalogClient: CatalogClient,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private readonly searchFilterService: SearchFilterService,
		private readonly allowActionService: AllowActionService
	) {}

	ngOnInit(): void {
		this.router.events
			.pipe(
				takeUntil(this.unsubscribe$),
				filter((e): e is NavigationEnd => e instanceof NavigationEnd)
			)
			.subscribe(_ => {
				this.queryInput = this.route.snapshot.queryParamMap.get('query') ?? '';
				this.searchCatalog();
			});
		this.searchCatalog();
		this.cannotCreateDataset$ = this.allowActionService.globalAllowActions$.pipe(
			takeUntil(this.unsubscribe$),
			map(result => {
				let allowCreate = result.find(x => x.type === CatalogType.Dataset)?.allowCreate;
				return allowCreate !== undefined ? !allowCreate : true;
			}),
			startWith(true)
		);
		this.cannotCreateDataSercive$ = this.allowActionService.globalAllowActions$.pipe(
			takeUntil(this.unsubscribe$),
			map(result => {
				let allowCreate = result.find(x => x.type === CatalogType.DataService)?.allowCreate;
				return allowCreate !== undefined ? !allowCreate : true;
			}),
			startWith(true)
		);
		this.cannotCreatePubicService$ = this.allowActionService.globalAllowActions$.pipe(
			takeUntil(this.unsubscribe$),
			map(result => {
				let allowCreate = result.find(x => x.type === CatalogType.PublicService)?.allowCreate;
				return allowCreate !== undefined ? !allowCreate : true;
			}),
			startWith(true)
		);
	}

	ngAfterViewInit(): void {
		this.updateFilterSubject.next();
	}

	ngOnDestroy() {
		this.unsubscribe$.next(1);
		this.unsubscribe$.complete();
	}

	onChangePage(pageEvent: PageEvent) {
		const queryParams = {
			page: pageEvent.pageIndex + 1 === this.defaultPage ? null : pageEvent.pageIndex + 1,
			pageSize: pageEvent.pageSize === this.defaultPageSize ? null : pageEvent.pageSize
		};
		this.router.navigate([], {
			queryParams,
			queryParamsHandling: 'merge'
		});
	}

	search(): void {
		if (this.queryInput) {
			const queryParams = {query: this.queryInput ?? null};
			this.router.navigate([], {
				relativeTo: this.route,
				queryParams: {...queryParams, page: null},
				queryParamsHandling: 'merge'
			});
		}
	}

	onResetQuery(): void {
		this.queryInput = '';
		this.query = undefined;
		this.router.navigate(['./'], {
			relativeTo: this.route,
			queryParams: {query: null, page: null, pageSize: null},
			queryParamsHandling: 'merge'
		});
	}

	toggleFilterVisibility() {
		this.hideFilters = !this.hideFilters;
	}

	get searchType(): string {
		switch (this.route.snapshot.url[0].path) {
			case 'datasets':
				return SearchType.Dataset;
			case 'publicservices':
				return SearchType.Publicservice;
			case 'dataservices':
				return SearchType.Dataservice;
			case 'concepts':
				return SearchType.Concept;
			case 'all':
			default:
				return SearchType.All;
		}
	}

	getQueryParams(searchType: SearchType | undefined): Params {
		if (searchType) {
			let params: Params = {};
			const keys = SearchFilterService.getOrderedKeys(searchType);

			keys.forEach(k => {
				params[k] = this.route.snapshot.queryParams[k];
			});

			return {query: this.route.snapshot.queryParams.query, ...params};
		}

		return {query: this.route.snapshot.queryParams.query};
	}

	onCountResultChange(countResult: FilterCountResult) {
		this.countResults = countResult.types;
		this.allCount = countResult.totalDocCount!;
	}

	getCountResult(type: CatalogType): number {
		return this.countResults?.find(r => r.reference === type)?.count ?? 0;
	}

	private searchCatalog() {
		const queryParams = this.route.snapshot.queryParamMap;
		const filters = this.searchFilterService.getSelectedFilters(this.searchType);
		const query = this.route.snapshot.queryParamMap.get('query');
		const page = queryParams.has('page') ? Number(queryParams.get('page')) : this.defaultPage;
		const pageSize = queryParams.has('pageSize') ? Number(queryParams.get('pageSize')) : this.defaultPageSize;

		this.catalogClient // eslint-disable-next-line max-len
			.getSearchByQueryAndAccessRightsAndConceptValueTypesAndFormatsAndBusinessEventsAndLevelsAndLevelProposalsAndLifeEventsAndPublishersAndStatusesAndStatusProposalsAndThemesAndTypesAndPageAndPageSize(
				query ?? undefined,
				filters.accessRights ?? undefined,
				filters.conceptTypes.map(t => t as ConceptType) ?? undefined,
				filters.formats ?? undefined,
				filters.businessEvents ?? undefined,
				filters.levels ?? undefined,
				filters.levelProposals ?? undefined,
				filters.lifeEvents ?? undefined,
				filters.publishers ?? undefined,
				filters.statuses ?? undefined,
				filters.statusProposals ?? undefined,
				filters.themes ?? undefined,
				this.getCatalogTypes(filters),
				page ?? this.defaultPage,
				pageSize ?? this.defaultPageSize
			)
			.subscribe((response: SwaggerResponse<CatalogEntry[]>) => {
				this.pagingInfo = new SearchResultPagingInfo(response.headers);
				this.catalogEntry = response.result;
			});
	}

	private getCatalogTypes(filters: SearchFilters): string[] | undefined {
		switch (this.searchType) {
			case SearchType.Dataset:
				return [CatalogType.Dataset];
			case SearchType.Dataservice:
				return [CatalogType.DataService];
			case SearchType.Publicservice:
				return [CatalogType.PublicService];
			case SearchType.Concept:
				return [CatalogType.Concept];
			case SearchType.All:
			default:
				return filters.types ?? undefined;
		}
	}
}
