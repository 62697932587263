import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {CatalogEntry, ConceptType, ICatalogEntry} from '@bfs-sis/bfs-iop-admin-web-api-client';
import {SortableListViewComponent} from '../../shared/sortable-list-view/sortable-list-view.component';
import {filter, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {SearchResultPagingInfo} from '../../shared/searchResultPagingInfo';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {FormatFunctions} from 'src/app/shared/format-functions';

type SortableKeys = 'title' | 'identifiers' | 'publisherName' | 'registrationStatus' | 'publicationLevel';

@Component({
	selector: 'app-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss']
})
export class TableComponent extends SortableListViewComponent<ICatalogEntry, SortableKeys> implements OnInit, OnDestroy, OnChanges {
	@Input() dto: CatalogEntry[] = [];
	isTableView: boolean = false;

	readonly COLUMN_TITLE = 'title';
	readonly COLUMN_PUBLISHERNAME = 'publisherName';
	readonly COLUMN_STATUS = 'registrationStatus';
	readonly COLUMN_PUBLICATION = 'publicationLevel';
	readonly COLUMN_TYPE = 'type';
	readonly COLUMN_CONCEPTTYPE = 'conceptType';
	readonly COLUMN_VERSION = 'version';
	readonly COLUMN_ACTIONS = 'actions';
	readonly COLUMN_VALID_FROM = 'validFrom';
	readonly COLUMN_VALID_TO = 'validTo';

	displayedColumns: string[] = [
		this.COLUMN_TITLE,
		this.COLUMN_PUBLISHERNAME,
		this.COLUMN_TYPE,
		this.COLUMN_STATUS,
		this.COLUMN_PUBLICATION,
		this.COLUMN_VERSION,
		this.COLUMN_ACTIONS
	];

	currentLanguage: string;
	pagingInfo: SearchResultPagingInfo = new SearchResultPagingInfo(undefined);
	dataSource = new MatTableDataSource<ICatalogEntry | any>([]);

	private readonly unsubscribe$ = new Subject();

	constructor(readonly translate: TranslateService, private readonly route: ActivatedRoute, private readonly router: Router) {
		super(translate);

		this.currentLanguage = this.translate.currentLang;
	}

	ngOnInit(): void {
		this.translate.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe((language: LangChangeEvent) => {
			this.currentLanguage = language.lang;
		});

		this.router.events
			.pipe(
				takeUntil(this.unsubscribe$),
				filter((e): e is NavigationEnd => e instanceof NavigationEnd)
			)
			.subscribe(_ => {
				this.updateVisibleColumns();
			});
		this.updateVisibleColumns();
	}

	ngOnChanges(changes: SimpleChanges) {
		const change = changes.dto;
		if (change.currentValue !== undefined) {
			this.data = this.dto;
		}
	}

	ngOnDestroy() {
		this.unsubscribe$.next(1);
		this.unsubscribe$.complete();
	}

	setTableView() {
		this.isTableView = true;
	}

	setListView() {
		this.isTableView = false;
	}

	isDataSet(catalogEntry: CatalogEntry): boolean {
		return catalogEntry.type === 'Dataset';
	}

	isDataService(catalogEntry: CatalogEntry): boolean {
		return catalogEntry.type === 'DataService';
	}

	isConcept(catalogEntry: CatalogEntry): boolean {
		return catalogEntry.type === 'Concept';
	}

	getRouterLink(catalogEntry: CatalogEntry): string {
		let routerLink = '';

		switch (catalogEntry.type) {
			case 'Dataset':
				routerLink = `../datasets/${catalogEntry.id}`;
				break;
			case 'DataService':
				routerLink = `../dataservices/${catalogEntry.id}`;
				break;
			case 'PublicService':
				routerLink = `../publicservices/${catalogEntry.id}`;
				break;
			case 'Concept':
				routerLink = `../concepts/${catalogEntry.id}`;
				break;
			default:
				break;
		}

		return routerLink;
	}

	getBackTo(): Params {
		return {backto: this.route.snapshot.url[0].path ?? undefined};
	}

	getType(catalogEntry: CatalogEntry): string | undefined {
		let type: string | undefined;

		if (catalogEntry?.type) {
			type = catalogEntry.type.toLocaleLowerCase();
		}

		return type;
	}

	getFormattedDate(date: Date | undefined): string | null {
		return FormatFunctions.getFormattedDate(date, this.translate);
	}

	getConceptType(conceptType: ConceptType): string {
		const conceptTypeEnum = ConceptType;
		let type: string = '';

		if (conceptType) {
			type = conceptTypeEnum[conceptType].toLocaleLowerCase();
		}

		return type;
	}

	private updateVisibleColumns() {
		switch (this.route.snapshot.url[0].path) {
			case 'publicservices':
				this.displayedColumns = [
					this.COLUMN_TITLE,
					this.COLUMN_PUBLISHERNAME,
					this.COLUMN_TYPE,
					this.COLUMN_STATUS,
					this.COLUMN_PUBLICATION,
					this.COLUMN_ACTIONS
				];
				break;
			case 'concepts':
				this.displayedColumns = [
					this.COLUMN_TITLE,
					this.COLUMN_PUBLISHERNAME,
					this.COLUMN_TYPE,
					this.COLUMN_CONCEPTTYPE,
					this.COLUMN_STATUS,
					this.COLUMN_PUBLICATION,
					this.COLUMN_VERSION,
					this.COLUMN_VALID_FROM,
					this.COLUMN_VALID_TO,
					this.COLUMN_ACTIONS
				];
				break;
			case 'datasets':
			case 'dataservices':
			case 'all':
			default:
				this.displayedColumns = [
					this.COLUMN_TITLE,
					this.COLUMN_PUBLISHERNAME,
					this.COLUMN_TYPE,
					this.COLUMN_STATUS,
					this.COLUMN_PUBLICATION,
					this.COLUMN_VERSION,
					this.COLUMN_ACTIONS
				];
				break;
		}
	}
}
