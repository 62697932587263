import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'publicationlevelclass'})
export class PublicationLevelClassPipe implements PipeTransform {
	transform(source: string | undefined): string {
		switch (source) {
			case 'I14Y Public':
				return 'success';
			case 'Unit':
			default:
				return 'info';
		}
	}
}
