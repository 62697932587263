import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChildren} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {CatalogClient, CatalogType, ConceptType, FilterCountResult, FilterCountResultItem, SwaggerResponse} from '@bfs-sis/bfs-iop-admin-web-api-client';
import {TranslateService} from '@ngx-translate/core';
import {filter, Observable, skip, Subject, Subscription, takeUntil} from 'rxjs';
import {FallbackPipe} from 'src/app/shared/fallback/fallback.pipe';
import {FilterMultiSelectDropdownComponent} from './filter-multiselect-dropdown/filter-multiselect-dropdown.component';
import {SearchFilterService} from './search-filters.service';
import {SearchFilters, SearchType} from './search-filters';

@Component({
	selector: 'app-search-filters',
	templateUrl: './search-filters.component.html',
	styleUrls: ['./search-filters.component.scss']
})
export class SearchFiltersComponent implements OnInit, OnDestroy {
	filters!: {[key: string]: FilterCountResultItem[]};
	selectedValues!: {[key: string]: string[]};
	orderedKeys: string[] = [];

	@Input() hideFilters: boolean = true;
	@Input() searchType: SearchType | undefined;
	@Input() updateFilter: Observable<void> | undefined;
	@Output() countResultChange: EventEmitter<FilterCountResult> = new EventEmitter();

	@ViewChildren(FilterMultiSelectDropdownComponent) private readonly multiSelects!: FilterMultiSelectDropdownComponent[];

	private updateSubscription: Subscription | undefined;
	private readonly unsubscribe$ = new Subject();

	constructor(
		public readonly translate: TranslateService,
		public readonly searchFilterService: SearchFilterService,
		private readonly route: ActivatedRoute,
		private readonly router: Router,
		private readonly catalogClient: CatalogClient,
		private readonly fallbackPipe: FallbackPipe
	) {}

	ngOnInit(): void {
		this.translate.onLangChange.subscribe(_ => this.loadFilters());
		this.route.queryParams.pipe(skip(1)).subscribe(_ => this.setFilters(this.filters));

		this.router.events
			.pipe(
				takeUntil(this.unsubscribe$),
				filter((e): e is NavigationEnd => e instanceof NavigationEnd)
			)
			.subscribe(_ => this.loadFilters());
		this.loadFilters();

		if (this.updateFilter) {
			this.updateSubscription = this.updateFilter.subscribe(() => this.loadFilters());
		}
	}

	ngOnDestroy() {
		this.unsubscribe$.next(1);
		this.unsubscribe$.complete();
		if (this.updateSubscription) {
			this.updateSubscription.unsubscribe();
		}
	}

	toggleFilterVisibility() {
		this.hideFilters = !this.hideFilters;
	}

	selectionChange(section: string, selectedValues: string[]) {
		let deselected = this.getAllDeselected(this.selectedValues[section], selectedValues);

		if (deselected.length > 0) {
			deselected.forEach(item => {
				this.toggleFilter(section, item);
			});
			this.multiSelects.find(m => m.section === section)?.multiselectForm.controls.selectedOptions.setValue(this.selectedValues[section]);
		}

		selectedValues.forEach(value => {
			if (!this.selectedValues[section].includes(value)) {
				this.toggleFilter(section, value);
			}
		});
	}

	toggleFilter(section: string, value: string) {
		const i = this.selectedValues[section].indexOf(value);
		if (i > -1) {
			this.selectedValues[section].splice(i, 1);
		} else {
			this.selectedValues[section][this.selectedValues[section].length] = value;
		}
		this.navigateToFilterState();
	}

	toggleSection(section: string) {
		if (this.isEntireSectionSelected(section)) {
			this.selectedValues[section] = [];
		} else {
			this.selectedValues[section] = this.filters[section].map(x => x.reference!);
		}

		this.navigateToFilterState();
	}

	isIndeterminate(section: string) {
		return this.selectedValues[section].length > 0 && this.selectedValues[section].length !== this.filters[section].length;
	}

	isEntireSectionSelected(section: string): boolean {
		return this.selectedValues[section].length === this.filters[section].length;
	}

	resetAllFilter() {
		this.orderedKeys.forEach(key => {
			this.selectedValues[key] = [];
			this.multiSelects.find(multiselect => multiselect.section === key)?.resetAllSelections();
		});

		this.navigateToFilterState();
	}

	resetFilter(section: string) {
		this.selectedValues[section] = [];
		this.navigateToFilterState();
	}

	onChipRemove(section: string, toRemove: string) {
		this.toggleFilter(section, toRemove);
		this.multiSelects.find(m => m.section === section)?.multiselectForm.controls.selectedOptions.setValue(this.selectedValues[section]);
	}

	toggleAllSections() {
		if (this.areAllSectionSelected()) {
			this.orderedKeys.forEach(x => (this.selectedValues[x] = []));
		} else {
			this.orderedKeys.forEach(x => (this.selectedValues[x] = this.filters[x].map(c => c.reference!)));
		}
		this.navigateToFilterState();
	}

	areAllSectionsIndeterminate() {
		return this.orderedKeys.some(x => this.isIndeterminate(x));
	}

	areAllSectionSelected(): boolean {
		return this.orderedKeys.every(x => this.selectedValues[x].length === this.filters[x].length);
	}

	onDeselectAll() {
		if (this.searchType) {
			const keys = SearchFilterService.getOrderedKeys(this.searchType);
			Object.assign(this.selectedValues, ...keys.map(k => ({[k]: []})));
			this.navigateToFilterState();
		}
	}

	getFilterSection(section: string) {
		let sectionText;

		this.translate.get(`i18n.shared.filters.${section.toLowerCase()}`).subscribe(result => {
			sectionText = result;
		});

		return `${sectionText}: `;
	}

	getFilterLabel(section: string, selected: string) {
		const selectedFilter = this.filters[section].find(x => x.reference === selected);
		const selectedFilterLabel = this.fallbackPipe.transform(selectedFilter?.label, this.translate.currentLang);
		let label;

		if (selectedFilterLabel) {
			label = `${selectedFilterLabel} (${selectedFilter?.count})`;
		} else {
			const reference = selectedFilter?.reference?.toLowerCase();
			if (reference) {
				this.translate.get(this.searchFilterService.getI18n(section, reference)).subscribe(result => {
					label = `${result} (${selectedFilter?.count})`;
				});
			}
		}

		return label;
	}

	hasSelectedFilters(): boolean {
		let hasSelectedFilters = false;
		this.orderedKeys.forEach(key => {
			hasSelectedFilters = hasSelectedFilters || this.selectedValues[key].length > 0;
		});

		return hasSelectedFilters;
	}

	getSelectedFilters(section: string) {
		return this.filters[section].filter(item => {
			return this.selectedValues[section].includes(item.reference!);
		});
	}

	private loadFilters() {
		if (this.searchType) {
			const filters = this.searchFilterService.getSelectedFilters(this.searchType);

			this.loadFilterCounter(filters);
			this.loadTabCounter(filters);
		}
	}

	private loadTabCounter(filters: SearchFilters) {
		this.catalogClient // eslint-disable-next-line max-len
			.getSearchcountByQueryAndAccessRightsAndConceptValueTypesAndBusinessEventsAndFormatsAndLevelsAndLevelProposalsAndLifeEventsAndPublishersAndStatusesAndStatusProposalsAndThemesAndTypes(
				this.route.snapshot.queryParamMap.get('query') ?? undefined,
				filters.accessRights ?? undefined,
				filters.conceptTypes.map(t => t as ConceptType) ?? undefined,
				filters.businessEvents ?? undefined,
				filters.formats ?? undefined,
				filters.levels ?? undefined,
				filters.levelProposals ?? undefined,
				filters.lifeEvents ?? undefined,
				filters.publishers ?? undefined,
				filters.statuses ?? undefined,
				filters.statusProposals ?? undefined,
				filters.themes ?? undefined,
				undefined
			)
			.subscribe((x: SwaggerResponse<FilterCountResult>) => {
				this.countResultChange.emit(x.result);
			});
	}

	private loadFilterCounter(filters: SearchFilters) {
		this.catalogClient // eslint-disable-next-line max-len
			.getSearchcountByQueryAndAccessRightsAndConceptValueTypesAndBusinessEventsAndFormatsAndLevelsAndLevelProposalsAndLifeEventsAndPublishersAndStatusesAndStatusProposalsAndThemesAndTypes(
				this.route.snapshot.queryParamMap.get('query') ?? undefined,
				filters.accessRights ?? undefined,
				filters.conceptTypes.map(t => t as ConceptType) ?? undefined,
				filters.businessEvents ?? undefined,
				filters.formats ?? undefined,
				filters.levels ?? undefined,
				filters.levelProposals ?? undefined,
				filters.lifeEvents ?? undefined,
				filters.publishers ?? undefined,
				filters.statuses ?? undefined,
				filters.statusProposals ?? undefined,
				filters.themes ?? undefined,
				this.getCatalogTypes(filters)
			)
			.subscribe((x: SwaggerResponse<FilterCountResult>) => {
				this.setFiltersFromCountResult(x.result);
			});
	}

	private setFiltersFromCountResult(counters: FilterCountResult): void {
		if (counters.publicationLevelProposals && counters.publicationLevelProposals.length > 0) {
			counters.publicationLevelProposals = counters.publicationLevelProposals.filter(x => x.reference !== '' && x.reference !== null);
		}

		if (counters.registrationStatusProposals && counters.registrationStatusProposals.length > 0) {
			counters.registrationStatusProposals = counters.registrationStatusProposals.filter(x => x.reference !== '' && x.reference !== null);
		}

		if (counters.publicationLevels && counters.publicationLevels.length > 0) {
			counters.publicationLevels = counters.publicationLevels.filter(x => x.reference !== '' && x.reference !== null);
		}

		if (counters.registrationStatuses && counters.registrationStatuses.length > 0) {
			counters.registrationStatuses = counters.registrationStatuses.filter(x => x.reference !== '' && x.reference !== null);
		}

		if (this.searchType) {
			const filters: {[key: string]: FilterCountResult[]} = {};
			const keys = SearchFilterService.getOrderedKeys(this.searchType);

			keys.forEach(element => {
				filters[element] = SearchFilterService.getKeyFilters(counters, element);
			});
			this.setFilters(filters);
		}
	}

	private setFilters(filters: {[key: string]: FilterCountResult[]}): void {
		if (this.searchType) {
			const keys = SearchFilterService.getOrderedKeys(this.searchType);
			const queryValues = this.getselectedValuesFromQueryParams(keys);
			// selectedValues must be set before filters and keys
			this.selectedValues = queryValues;
			this.filters = filters;
			this.orderedKeys = keys;
		}
	}

	private getselectedValuesFromQueryParams(keys: string[]): {[key: string]: string[]} {
		const queryParams = this.route.snapshot.queryParamMap;
		return Object.assign({}, ...keys.map(k => ({[k]: queryParams.getAll(k)})));
	}

	private navigateToFilterState() {
		const queryParams = Object.assign(this.selectedValues);
		this.router.navigate([], {
			relativeTo: this.route,
			queryParams: {...queryParams, page: null},
			queryParamsHandling: 'merge'
		});
	}

	private getAllDeselected(selected: string[], selectedValues: string[]): string[] {
		let deselected: string[] = [];

		selected.forEach(s => {
			if (!selectedValues.find(f => f === s)) {
				deselected.push(s);
			}
		});

		return deselected;
	}

	private getCatalogTypes(filters: SearchFilters): string[] | undefined {
		switch (this.searchType) {
			case SearchType.Dataset:
				return [CatalogType.Dataset];
			case SearchType.Dataservice:
				return [CatalogType.DataService];
			case SearchType.Publicservice:
				return [CatalogType.PublicService];
			case SearchType.Concept:
				return [CatalogType.Concept];
			case SearchType.All:
			default:
				return filters.types ?? undefined;
		}
	}
}
