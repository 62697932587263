<h6 mat-dialog-title>{{ dialogConfig.headerText }}</h6>
<div mat-dialog-content>{{ dialogConfig.bodyText }}</div>
<div mat-dialog-actions [ngSwitch]="dialogConfig.dialogType">
	<div *ngSwitchCase="dialogType.info">
		<button type="button" mat-button obButton="primary" mat-dialog-close cdkFocusInitial>
			{{ dialogConfig.okButtonText }}
		</button>
	</div>
	<div *ngSwitchCase="dialogType.confirm">
		<button type="button" mat-button obButton="secondary" mat-dialog-close cdkFocusInitial (click)="onCancelClick()">
			{{ dialogConfig.cancelButtonText }}
		</button>
		<button type="button" mat-button obButton="primary" mat-dialog-close (click)="onConfirmClick()">
			{{ dialogConfig.confirmButtonText }}
		</button>
	</div>
	<div *ngSwitchCase="dialogType.save">
		<button type="button" mat-button obButton="secondary" mat-dialog-close cdkFocusInitial (click)="onCancelClick()">
			{{ dialogConfig.cancelButtonText }}
		</button>
		<button type="button" mat-button obButton="secondary" mat-dialog-close (click)="onDiscardChangesClick()">
			{{ dialogConfig.discardChangesButtonText }}
		</button>
		<button type="button" mat-button obButton="primary" mat-dialog-close (click)="onSaveChangesClick()" [disabled]="!dialogConfig.enableSave">
			{{ dialogConfig.saveChangesButtonText }}
		</button>
	</div>
</div>
