<div>
	<ng-container *ngIf="showHeader">
		<h2>{{ "i18n.shared.codelist.title" | translate }}</h2>

		<div class="filter-wrapper" *ngIf="setFilter">
			<p>{{ "i18n.shared.codelist.filter.set" | translate }}&#58;</p>
			<mat-chip-set *ngIf="setFilter">
				<mat-chip class="info">
					{{ setFilter?.code }}
					{{ "i18n.page.datasets.dataElement.filter." + setFilter?.operator | translate }}
					{{ setFilter?.criterium }}
				</mat-chip>
			</mat-chip-set>
		</div>
	</ng-container>

	<table mat-table matSort [dataSource]="dataSource" multiTemplateDataRows aria-label="table">
		<ng-container [matColumnDef]="COLUMN_VALUE">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>
				{{ "i18n.shared.codelist.value" | translate }}
			</th>
			<td mat-cell *matCellDef="let element">
				<span>{{ element.value ?? "-" }}</span>
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_NAME">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>
				{{ "i18n.shared.codelist.name" | translate }}
			</th>
			<td mat-cell *matCellDef="let element">
				{{ (element.name | fallback: currentLanguage) ?? "-" }}
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_PARENTCODE">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>
				{{ "i18n.shared.codelist.parentcode" | translate }}
			</th>
			<td mat-cell *matCellDef="let element">
				<span>{{ element.parentCode ?? "-" }}</span>
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_ADDITIONALINFO">
			<th mat-header-cell *matHeaderCellDef>
				{{ "i18n.shared.codelist.additionalInfo" | translate }}
			</th>
			<td mat-cell *matCellDef="let element">
				<div
					mat-icon-button
					aria-label="expand row"
					(click)="expandedElement = expandedElement === element ? null : element; $event.stopPropagation()"
					*ngIf="hasAdditionalInfo(element)"
				>
					<mat-icon *ngIf="expandedElement !== element" svgIcon="chevron-down" class="ob-icon iconsize"></mat-icon>
					<mat-icon *ngIf="expandedElement === element" svgIcon="chevron-up" class="ob-icon iconsize"></mat-icon>
				</div>
			</td>
		</ng-container>

		<ng-container matColumnDef="expandedDetail">
			<td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
				<div class="element-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
					<div class="expand-container">
						<div class="font-weight-600 element-detail-row">{{ "i18n.shared.codelist-entry-detail.description.title" | translate }}</div>
						<div class="element-detail-row">{{ (element!.description | fallback: currentLanguage) ?? "-" }}</div>
						<br />
						<div class="font-weight-600 element-detail-row">{{ "i18n.shared.codelist-entry-detail.annotation.title" | translate }}</div>
						<app-table-annotation class="element-detail-row" [annotations]="element.annotations"></app-table-annotation>
					</div>
				</div>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let element; columns: displayedColumns" class="element-row"></tr>
		<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
	</table>

	<div class="table-info-box">
		<app-infobox-no-content [datasource]="dataSource.data"></app-infobox-no-content>
	</div>
</div>
