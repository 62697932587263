import {SelectionModel} from '@angular/cdk/collections';
import {HttpStatusCode} from '@angular/common/http';
import {Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {CatalogClient, CatalogEntry, DataServiceInputClient, Dataset, MultiLanguage, SwaggerResponse} from '@bfs-sis/bfs-iop-admin-web-api-client';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {ObNotificationService} from '@oblique/oblique';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FallbackPipe} from '../fallback/fallback.pipe';

@Component({
	selector: 'app-edit-table-dataset-link',
	templateUrl: './edit-table-dataset-link.component.html',
	styleUrls: ['./edit-table-dataset-link.component.scss']
})
export class EditTableDatasetLinkComponent implements OnInit, OnDestroy {
	@ViewChild(MatSort, {static: false}) sort!: MatSort;
	@ViewChildren('focusInputField') focusInputFields!: QueryList<ElementRef>;
	@Input() dataServiceInputId!: string;

	currentLanguage: string = 'En';
	dto: Dataset[] = [];
	dataSource = new MatTableDataSource<Dataset>(this.dto);

	COLUMN_SELECT = 'select';
	COLUMN_TITLE = 'title';
	COLUMN_IDENTIFIER = 'identifiers';
	COLUMN_STATUS = 'registrationStatus';
	COLUMN_PUBLICATION = 'publicationLevel';
	COLUMN_ACTIONS = 'actions';

	displayedColumns: string[] = [
		this.COLUMN_SELECT,
		this.COLUMN_TITLE,
		this.COLUMN_IDENTIFIER,
		this.COLUMN_STATUS,
		this.COLUMN_PUBLICATION,
		this.COLUMN_ACTIONS
	];

	searchedDatasetTitle: (string | null)[] = [];
	statusList = new Map<string, string>();
	publicationList = new Map<string, string>();

	private isEditing = false;
	private isAddMode = false;
	private rowIndex: number | undefined;
	private selection = new SelectionModel<Dataset>(true, []);
	private allDatasets: CatalogEntry[] = [];
	private selectedParentCodeValue: string | undefined = undefined;
	private editedParentCodeValue: string | undefined = undefined;
	private readonly idList = new Map<string, string>();
	private readonly unsubscribe$ = new Subject();

	constructor(
		private readonly translate: TranslateService,
		private readonly dataServiceInputClient: DataServiceInputClient,
		private readonly notification: ObNotificationService,
		private readonly catalogClient: CatalogClient,
		private readonly fallbackPipe: FallbackPipe
	) {
		this.currentLanguage = this.translate.currentLang;
	}

	ngOnInit(): void {
		this.dataSource.sort = this.sort;
		this.getDataSource();
		this.translate.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe((language: LangChangeEvent) => {
			this.currentLanguage = language.lang;
		});

		this.getAllLinkedDatasets();
	}

	ngOnDestroy() {
		this.unsubscribe$.next(1);
		this.unsubscribe$.complete();
	}

	canAdd(): boolean {
		return !this.isEditing && !this.hasSelectedItems();
	}

	canEdit(): boolean {
		return !this.isEditing;
	}

	canRemove(): boolean {
		return !this.isEditing;
	}

	canSave(rowIndex: number): boolean {
		if (this.isRowEditMode(rowIndex)) {
			if (this.dataSource.data[rowIndex].title) {
				return true;
			}
		}
		return false;
	}

	canSelect(): boolean {
		return !this.isEditing;
	}

	displayParentCode(code: Dataset) {
		return code?.title ?? '';
	}

	hasRows(): boolean {
		return this.dataSource?.data?.length > 0;
	}

	hasSelectedRows(): boolean {
		return this.selection.hasValue();
	}

	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;

		return numSelected === numRows;
	}

	isEditMode(): boolean {
		return this.isEditing;
	}

	isRowEditMode(index: number): boolean {
		return this.isEditing && this.rowIndex === index;
	}

	isRowSelected(row: Dataset): boolean {
		return this.selection.isSelected(row);
	}

	reloadEntries(): void {
		this.getDataSource();
	}

	onAddRow(): void {
		let numRows = this.dataSource.data.push(this.createEmptyDto());
		this.editedParentCodeValue = '';

		this.isAddMode = true;
		this.UpdateIsEditing(true);
		this.rowIndex = numRows - 1;
		this.refreshDatabinding();
		this.setFocus();
	}

	onCheckParentCodeValue(parentCodeValue: string | null | undefined): boolean {
		if (parentCodeValue) {
			this.editedParentCodeValue = parentCodeValue;
			return this.checkParentCodeValue(parentCodeValue);
		}
		this.editedParentCodeValue = '';
		return false;
	}

	onClickOnHeader(): void {
		setTimeout(() => {
			this.sortDto();
		}, 200);
	}

	onClickOnTitle(query: string): void {
		this.getAutocompleteList(query);
	}

	onDiscard() {
		this.isAddMode = false;
		this.UpdateIsEditing(false);
		this.dataSource.data.splice(this.rowIndex!, 1);

		this.rowIndex! -= 1;
		this.refreshDatabinding();
		this.setFocus();
	}

	onMasterToggle(): void {
		if (this.isAllSelected()) {
			this.selection.clear();
		} else {
			this.dataSource.data.forEach(row => this.selection.select(row));
		}
	}

	onRemoveRow(index: number, row?: Dataset): void {
		this.dataSource.data.splice(index, 1);

		if (!this.isAddMode) {
			this.deleteRequest(row as Dataset).then(success => {
				if (success) {
					this.getDataSource();
					this.refreshDatabinding();
				} else {
					this.showErrorNotification();
				}
			});
		}
	}

	onRemoveSelectedRows(): void {
		this.selection.selected.forEach(item => {
			let index: number = this.dataSource.data.findIndex((d: Dataset) => d === item);
			let dataset: Dataset | undefined = this.dataSource.data.find((d: Dataset) => d === item);
			this.onRemoveRow(index, dataset);
		});
		this.selection = new SelectionModel<Dataset>(true, []);
	}

	async onSave(row: Dataset) {
		if (this.onCheckParentCodeValue(this.editedParentCodeValue)) {
			this.isAddMode = false;
			this.UpdateIsEditing(false);
			if (row.id) {
				if (await this.putRequest(row.id)) {
					this.getDataSource();
					this.refreshDatabinding();
					this.setFocus();
				}
			}
		} else {
			this.onDiscard();
			this.showErrorNotification();
		}
	}

	onToggleSelection(row: Dataset): void {
		this.selection.toggle(row);
	}

	onParentCodeInputChanged(query: string): void {
		this.getAutocompleteList(query);
	}

	async onParentCodeSelection(parentCodeValue: string) {
		this.selectedParentCodeValue = parentCodeValue;
		if (this.checkParentCodeValue(parentCodeValue)) {
			const id = this.idList.get(parentCodeValue);
			if (id) {
				this.isAddMode = false;
				this.UpdateIsEditing(false);
				if (await this.putRequest(id)) {
					this.showSuccessNotification();
					this.getDataSource();
					this.refreshDatabinding();
					this.setFocus();
				}
			}
		} else {
			this.onDiscard();
			this.showErrorNotification();
		}
	}

	private checkParentCodeValue(parentCodeValue: string): boolean {
		return this.searchedDatasetTitle.findIndex((d: any) => d === parentCodeValue) > -1;
	}

	private createEmptyDto(): Dataset {
		return new Dataset({
			description: new MultiLanguage({
				de: undefined,
				en: undefined,
				fr: undefined,
				it: undefined
			}),
			documents: [],
			id: undefined,
			status: undefined,
			identifiers: [],
			title: new MultiLanguage({
				de: undefined,
				en: undefined,
				fr: undefined,
				it: undefined
			})
		});
	}

	private validateHttpStatus(response: SwaggerResponse<void>, resolve: (value: boolean | PromiseLike<boolean>) => void) {
		if (response.status === HttpStatusCode.NoContent) {
			resolve(true);
		} else {
			resolve(false);
		}
	}

	private deleteRequest(datasetEntry: Dataset): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			if (this.dataServiceInputId && datasetEntry.id) {
				this.dataServiceInputClient.deleteServesDatasetsByIdAndDatasetId(this.dataServiceInputId, datasetEntry.id).subscribe(response => {
					this.validateHttpStatus(response, resolve);
				});
			}
		});
	}

	private getAutocompleteList(query: string) {
		if (this.dataServiceInputId) {
			this.statusList.clear();
			this.publicationList.clear();
			this.idList.clear();
			const currentList = this.dataSource.data.map(x => {
				return this.fallbackPipe.transform(x.title, this.currentLanguage) ?? '';
			});

			this.filterAutocompleteList(query, currentList);
		}
	}

	private filterAutocompleteList(query: string, currentList: string[]) {
		this.searchedDatasetTitle = this.allDatasets
			.map(x => {
				let tmp = this.mapCatalogEntryToAutocompleteList(x, currentList);
				if (query.length > 0) {
					return tmp ? filterQuery(tmp) : null;
				}
				return tmp;
			})
			.filter(x => x !== null)
			.sort((a, b) => {
				return a!.toLowerCase().localeCompare(b!.toLowerCase());
			});

		function filterQuery(tmp: string): string | null {
			return tmp.toLowerCase().includes(query.toLowerCase()) ? tmp : null;
		}
	}

	private mapCatalogEntryToAutocompleteList(x: CatalogEntry, currentList: string[]) {
		let tmp = this.fallbackPipe.transform(x.title, this.currentLanguage);
		if (tmp) {
			this.statusList.set(tmp, x.registrationStatus ?? '');
			this.publicationList.set(tmp, x.publicationLevel ?? '');
			this.idList.set(tmp, x.id ?? '');
		}
		if (tmp && currentList && currentList.length > 0) {
			//  Filter out existing entries from the temporary searchedDatasetTitle list
			const searchRes = currentList.findIndex(item => item === tmp);
			if (searchRes > -1) {
				tmp = null;
			}
		}
		return tmp;
	}

	private getDataSource(): void {
		if (this.dataServiceInputId) {
			this.dataServiceInputClient.getServesDatasetsById(this.dataServiceInputId).subscribe(response => {
				this.dto = response.result ?? [];
				this.dataSource = new MatTableDataSource<Dataset>(this.dto);
			});
		}
	}

	private getAllLinkedDatasets(): void {
		this.catalogClient // eslint-disable-next-line max-len
			.getSearchByQueryAndAccessRightsAndConceptValueTypesAndFormatsAndBusinessEventsAndLevelsAndLevelProposalsAndLifeEventsAndPublishersAndStatusesAndStatusProposalsAndThemesAndTypesAndPageAndPageSize(
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				['Dataset'],
				undefined,
				undefined
			)
			.subscribe((response: SwaggerResponse<CatalogEntry[]>) => {
				this.allDatasets = response.result;
			});
	}

	private hasSelectedItems(): boolean {
		return !this.selection.isEmpty();
	}

	private putRequest(id: string): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			if (this.dataServiceInputId && id) {
				this.dataServiceInputClient.putServesDatasetsByIdAndDatasetId(this.dataServiceInputId, id).subscribe(response => {
					this.validateHttpStatus(response, resolve);
				});
			}
		});
	}

	private refreshDatabinding(): void {
		this.dataSource.filter = '';
	}

	private showSuccessNotification(): void {
		this.notification.success('i18n.app.notification.save.succeeded');
	}

	private showErrorNotification(): void {
		this.notification.error('i18n.app.notification.save.error');
	}

	private setFocus(): void {
		setTimeout(() => {
			this.focusInputFields.get(this.rowIndex!)?.nativeElement.focus();
		}, 100);
	}

	private sortDto() {
		switch (this.sort?.active) {
			case this.COLUMN_TITLE:
				this.dto = [...this.dto].sort((a, b) => {
					const aTitle = this.fallbackPipe.transform(a.title, this.currentLanguage);
					const bTitle = this.fallbackPipe.transform(b.title, this.currentLanguage);
					return this.sortDtoSub(aTitle!, bTitle!);
				});
				break;
			case this.COLUMN_IDENTIFIER:
				this.dto = [...this.dto].sort((a, b) => {
					return this.sortDtoSub(a.identifiers!.toString(), b.identifiers!.toString());
				});
				break;
			case this.COLUMN_STATUS:
				this.dto = [...this.dto].sort((a, b) => {
					return this.sortDtoSub(a.registrationStatus!.toString(), b.registrationStatus!.toString());
				});
				break;
			case this.COLUMN_PUBLICATION:
				this.dto = [...this.dto].sort((a, b) => {
					return this.sortDtoSub(a.publicationLevel!.toString(), b.publicationLevel!.toString());
				});
		}

		this.dataSource = new MatTableDataSource<Dataset>(this.dto);
	}

	private sortDtoSub(a: string, b: string): number {
		if (this.sort.direction.toString() === 'asc') {
			return a.toLowerCase().localeCompare(b.toLowerCase());
		} else {
			return b.toLowerCase().localeCompare(a.toLowerCase());
		}
	}

	private UpdateIsEditing(value: boolean) {
		this.isEditing = value;
	}
}
