import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MultiLanguage} from '@bfs-sis/bfs-iop-admin-web-api-client';

@Component({
	selector: 'app-modal-dialog-annotation',
	templateUrl: './modal-dialog.component.html'
})
export class ModalDialogAnnotationComponent implements OnInit {
	form!: UntypedFormGroup;
	constructor(private readonly dialogRef: MatDialogRef<ModalDialogAnnotationComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

	ngOnInit(): void {
		this.form = new UntypedFormGroup({
			type: new UntypedFormControl('', [Validators.required]),
			title: new UntypedFormControl(''),
			identifier: new UntypedFormControl(''),
			text: new UntypedFormGroup(this.getObjectFromKeys(this.data.contentLanguages, () => new UntypedFormControl())),
			uri: new UntypedFormControl('')
		});

		this.mapDataToForm();
	}

	get isSaveDisabled(): boolean {
		return !this.form.dirty;
	}

	onSubmit() {
		this.mapFormToData();
		this.dialogRef.close(this.data);
	}

	private getObjectFromKeys<Type>(keys: readonly string[], initialValue: (key: string) => Type) {
		return Object.assign({}, ...keys.map(x => ({[x]: initialValue(x)})));
	}

	private mapDataToForm(): void {
		this.form.patchValue({
			text: new MultiLanguage(this.data.annotation.text),
			title: this.data.annotation.title,
			type: this.data.annotation.type,
			identifier: this.data.annotation.identifier,
			uri: this.data.annotation.uri
		});
	}

	private mapFormToData(): void {
		this.data.annotation.title = this.form.value.title;
		this.data.annotation.identifier = this.form.value.identifier;
		this.data.annotation.uri = this.form.value.uri;
		this.data.annotation.type = this.form.value.type;
		this.data.contentLanguages.forEach((l: string) => {
			this.data.annotation.text![l as keyof MultiLanguage] = this.form.value.text[l] || undefined;
		});
	}
}
