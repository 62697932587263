import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChild, ViewChildren} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {IMultiLanguage} from '@bfs-sis/bfs-iop-admin-web-api-client';
import {SelectionModel} from '@angular/cdk/collections';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
	selector: 'app-edit-table-multi-language',
	templateUrl: './edit-table-multi-language.component.html',
	styleUrls: ['./edit-table-multi-language.component.scss']
})
export class EditTableMultiLanguageComponent implements AfterViewInit, OnChanges {
	@ViewChild(MatSort) sort!: MatSort;
	@ViewChildren('focusInputField') focusInputFields!: QueryList<ElementRef>;
	@Input() parentForm!: any;
	@Input() dto: any;
	@Input() controlName!: string;
	@Output() edit: EventEmitter<boolean> = new EventEmitter();
	public dataSource = new MatTableDataSource<IMultiLanguage>([]);

	COLUMN_GERMAN = 'de';
	COLUMN_FRENCH = 'fr';
	COLUMN_ITALIAN = 'it';
	COLUMN_ENGLISH = 'en';
	COLUMN_SELECT = 'select';
	COLUMN_ACTIONS = 'actions';

	displayedColumns: string[] = [this.COLUMN_SELECT, this.COLUMN_GERMAN, this.COLUMN_FRENCH, this.COLUMN_ITALIAN, this.COLUMN_ENGLISH, this.COLUMN_ACTIONS];

	private initialRowValue: IMultiLanguage;
	private isEditing = false;
	private isAddMode = false;
	private rowIndex: number | undefined;
	private selection = new SelectionModel<IMultiLanguage>(true, []);

	constructor() {
		this.initialRowValue = this.newEntry();
	}

	ngAfterViewInit(): void {
		this.dataSource.sort = this.sort;
	}

	ngOnChanges(changes: SimpleChanges) {
		const change = changes.dto;
		if (change.currentValue !== undefined) {
			this.dataSource = new MatTableDataSource<IMultiLanguage>(this.dto);
			this.initResourceToTableData();
		}
	}

	onMasterToggle(): void {
		if (this.isAllSelected()) {
			this.selection.clear();
		} else {
			this.dataSource.data.forEach(row => this.selection.select(row));
		}
	}

	onRemoveRow(index: number): void {
		this.dataSource.data.splice(index, 1);
		this.resourceControl.removeAt(index);
		if (!this.isAddMode) {
			this.parentForm.markAsDirty();
		}
		this.refreshDatabinding();
	}

	onRemoveSelectedRows(): void {
		this.selection.selected.forEach(item => {
			const index: number = this.dataSource.data.findIndex((d: IMultiLanguage) => d === item);
			this.onRemoveRow(index);
		});
		this.selection = new SelectionModel<IMultiLanguage>(true, []);
	}

	onAddRow(): void {
		const numRows = this.dataSource.data.push(this.newEntry());

		this.parentForm.get(this.controlName).push(
			new UntypedFormGroup({
				de: new UntypedFormControl(''),
				fr: new UntypedFormControl(''),
				it: new UntypedFormControl(''),
				en: new UntypedFormControl('')
			})
		);

		this.isAddMode = true;
		this.UpdateIsEditing(true);
		this.rowIndex = numRows - 1;
		this.refreshDatabinding();
		this.setFocus();
	}

	type(ele: any, value: any, val: any) {
		ele[val] = value;
	}

	onDiscard() {
		if (this.isAddMode) {
			this.onRemoveRow(this.rowIndex!);
			this.isAddMode = false;
		} else {
			this.dataSource.data[this.rowIndex!] = this.copyRowValue(this.initialRowValue);
		}

		this.initResourceToTableData();
		this.refreshDatabinding();
		this.initialRowValue = this.newEntry();
		this.UpdateIsEditing(false);
	}

	onEditRow(rowIndex: number): void {
		this.initialRowValue = this.copyRowValue(this.dataSource.data[rowIndex]);
		this.UpdateIsEditing(true);
		this.rowIndex = +rowIndex;
		this.setFocus();
	}

	onSave() {
		this.initResourceToTableData();
		this.isAddMode = false;
		this.UpdateIsEditing(false);
	}

	canAdd(): boolean {
		return !this.isEditing && !this.hasSelectedItems();
	}

	canEdit(): boolean {
		return !this.isEditing;
	}

	canRemove(): boolean {
		return !this.isEditing;
	}

	canSave(rowIndex: number): boolean {
		if (this.isRowEditMode(rowIndex)) {
			if (this.dataSource.data[rowIndex]) {
				return true;
			}
		}
		return false;
	}

	canSelect(): boolean {
		return !this.isEditing;
	}

	hasSelectedRows(): boolean {
		return this.selection.hasValue();
	}

	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}

	isEditMode(): boolean {
		return this.isEditing;
	}

	isRowEditMode(index: number): boolean {
		return this.isEditing && this.rowIndex === index;
	}

	isRowSelected(row: IMultiLanguage): boolean {
		return this.selection.isSelected(row);
	}

	onToggleSelection(row: IMultiLanguage): void {
		this.selection.toggle(row);
	}

	private copyRowValue(source: IMultiLanguage): IMultiLanguage {
		return {
			de: source?.de,
			en: source?.en,
			fr: source?.fr,
			it: source?.it
		};
	}

	private hasSelectedItems(): boolean {
		return !this.selection.isEmpty();
	}

	private initResourceToTableData(): void {
		this.parentForm.setControl(
			this.controlName,
			new UntypedFormArray(
				this.dataSource.data.map(
					item =>
						new UntypedFormGroup({
							de: new UntypedFormControl(item?.de),
							fr: new UntypedFormControl(item?.fr),
							it: new UntypedFormControl(item?.it),
							en: new UntypedFormControl(item?.en)
						})
				)
			)
		);
	}

	private newEntry(): IMultiLanguage {
		return {
			de: undefined,
			en: undefined,
			fr: undefined,
			it: undefined
		};
	}

	private refreshDatabinding(): void {
		this.dataSource.filter = '';
	}

	private setFocus(): void {
		setTimeout(() => {
			this.focusInputFields.get(this.rowIndex!)?.nativeElement.focus();
		});
	}

	private UpdateIsEditing(value: boolean) {
		this.isEditing = value;
		this.edit.emit(value);
	}

	private get resourceControl(): any {
		return this.parentForm.get(this.controlName) as UntypedFormArray;
	}
}
