<div class="filter-wrapper">
	<div class="ob-grid filter-selection">
		<div class="filter-buttons ob-span-3">
			<div class="filter-button">
				<button mat-button obButton="secondary" (click)="toggleFilterVisibility()">
					<mat-icon [svgIcon]="'filter'"></mat-icon>
					{{ (hideFilters ? "i18n.shared.filters.show" : "i18n.shared.filters.hide") | translate }}
				</button>
			</div>
		</div>
		<div *ngFor="let section of orderedKeys" class="ob-span-3" [hidden]="hideFilters">
			<app-filter-multiselect-dropdown
				[selectedOptionsFromFilter]="selectedValues[section]"
				[translationDropdownFieldName]="'i18n.shared.filters.' + section.toLowerCase()"
				[dropdownOptions]="filters[section]"
				[section]="section"
				[displaySelectAllOption]="true"
				(changeEvent)="selectionChange(section, $event)"
			></app-filter-multiselect-dropdown>
		</div>
	</div>

	<div class="filter-chips">
		<mat-chip-set>
			<ng-container *ngFor="let section of orderedKeys">
				<mat-chip class="info" *ngFor="let selected of selectedValues[section]" (removed)="onChipRemove(section, selected)" [removable]="true">
					{{ getFilterSection(section) }}
					{{ getFilterLabel(section, selected) }}
					<mat-icon [svgIcon]="'cancel-circle'" matChipRemove></mat-icon>
				</mat-chip>
			</ng-container>
			<ng-container *ngIf="hasSelectedFilters()">
				<button mat-button [obButton]="'tertiary'" (click)="resetAllFilter()">
					<mat-icon [svgIcon]="'undo'"></mat-icon>
					{{ "i18n.shared.filters.removeall" | translate }}
				</button>
			</ng-container>
		</mat-chip-set>
	</div>
</div>
