import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ObINavigationLink, ObMasterLayoutComponent} from '@oblique/oblique';
import {SessionService} from './services/session.service';
import {map, Observable, Subject, takeUntil} from 'rxjs';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {IAgent, UsersClient} from '@bfs-sis/bfs-iop-admin-web-api-client';
import {DataserviceInjectablesService} from './dataservices/services/dataservice-injectables.service';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: []
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
	currentYear = new Date().getFullYear();
	isLoggedIn: Observable<boolean>;
	agents: IAgent[] = [];
	token: any;
	access_token!: string;
	currentLanguage: string;
	businessRole: string | undefined = '';
	navigation: ObINavigationLink[] = [];

	@ViewChild(ObMasterLayoutComponent) private readonly masterLayout: ObMasterLayoutComponent | undefined;

	private readonly unsubscribe$ = new Subject();

	constructor(
		private readonly dataserviceInjectablesService: DataserviceInjectablesService,
		private readonly sessionService: SessionService,
		private readonly usersClient: UsersClient,
		private readonly translate: TranslateService,
		private readonly titleService: Title,
		private readonly router: Router
	) {
		this.currentLanguage = this.translate.currentLang;
		this.translate.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe((language: LangChangeEvent) => (this.currentLanguage = language.lang));
		this.isLoggedIn = this.sessionService.isAuthenticated$;
	}

	ngOnInit() {
		this.currentLanguage = this.translate.currentLang;
		this.sessionService.getTokenPropertiesAsObservable().subscribe(x => {
			this.token = x;
			if (this.token) {
				this.generateAccessToken();
				this.getAgents();
				this.usersClient
					.getUserInfo()
					.pipe(map(userInfo => userInfo.result.businessRole))
					.subscribe(firstName => {
						this.businessRole = firstName;
					});
			}
		});

		this.navigation = [
			{
				url: 'home',
				label: 'i18n.navigation.home'
			},
			{
				url: 'catalog',
				label: 'i18n.navigation.catalog'
			},
			{
				url: 'https://i14y-ch.github.io/handbook/' + this.currentLanguage,
				label: 'i18n.navigation.handbook',
				isExternal: true
			}
		];
	}

	ngAfterViewInit(): void {
		this.translate.stream('i18n.title.page').subscribe(result => {
			this.titleService.setTitle(result);
		});

		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(() => this.masterLayout!.scrollTarget?.scrollTo({top: 0, behavior: 'smooth'}));
	}

	ngOnDestroy() {
		this.unsubscribe$.next(1);
		this.unsubscribe$.complete();
	}

	getAgents(): void {
		this.dataserviceInjectablesService.agentClient.getUser().subscribe(response => {
			this.agents = response.result;
		});
	}

	generateAccessToken(): void {
		const accessToken = localStorage.getItem('access_token') ?? '';
		this.access_token = `Bearer ${accessToken}`;
	}

	login() {
		this.sessionService.login();
	}

	logout() {
		this.sessionService.logout();
	}
}
