import {Injectable} from '@angular/core';
import {AbstractControl, AsyncValidator, ValidationErrors} from '@angular/forms';
import {CodelistEntryInputClient} from '@bfs-sis/bfs-iop-admin-web-api-client';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class CodelistEntryCodeValidator implements AsyncValidator {
	private _initalValue: string | undefined;
	private _codelistId: string | undefined;

	get codeListId(): string | undefined {
		return this._codelistId;
	}

	set codeListId(value: string | undefined) {
		this._codelistId = value;
	}

	constructor(private readonly client: CodelistEntryInputClient) {}

	setInitalValue(initalValue: string | undefined) {
		this._initalValue = initalValue;
	}

	validate(control: AbstractControl): Observable<ValidationErrors | null> {
		if (control.value === this._initalValue || !control.value) {
			return of(null);
		}

		if (this.codeListId) {
			return this.client.getExistsByIdAndValue(this.codeListId, control.value).pipe(
				map(exists => (exists.result ? {codeExists: true} : null)),
				catchError(() => of(null))
			);
		}

		return of(null);
	}
}
