<div [formGroup]="parentForm">
	<table mat-table matSort [dataSource]="dataSource" [formArrayName]="controlName">
		<ng-container [matColumnDef]="COLUMN_ACTIONS">
			<th mat-header-cell *matHeaderCellDef>
				<div class="action-wrapper">
					<div class="action-button">
						<button
							id="{{ controlName + '-add-row' }}"
							obButton="secondary"
							mat-icon-button
							(click)="onAddRow()"
							[disabled]="!canAdd() || !datasetId"
							matTooltip="{{ 'i18n.app.edittable.button.addrow.tooltip' | translate }}"
						>
							<mat-icon svgIcon="plus" class="ob-icon"></mat-icon>
						</button>
					</div>
				</div>
			</th>
			<td mat-cell *matCellDef="let row; let i = index">
				<div class="action-wrapper">
					<div class="action-button" *ngIf="isRowEditMode(i)">
						<button
							id="{{ controlName + '-discard-row' + i }}"
							obButton="secondary"
							mat-icon-button
							(click)="onDiscard()"
							[disabled]="hasSelectedRows()"
							matTooltip="{{ 'i18n.app.edittable.button.discard.tooltip' | translate }}"
						>
							<mat-icon svgIcon="cancel" class="ob-icon"></mat-icon>
						</button>
					</div>
					<div class="action-button" *ngIf="isRowEditMode(i)">
						<button
							id="{{ controlName + '-save-row' + i }}"
							obButton="secondary"
							mat-icon-button
							(click)="onSave(row)"
							[disabled]="!canSave(i)"
							matTooltip="{{ 'i18n.app.edittable.button.save.tooltip' | translate }}"
						>
							<mat-icon svgIcon="checkmark" class="ob-icon"></mat-icon>
						</button>
					</div>
					<div class="action-button" *ngIf="canEdit()">
						<button
							id="{{ controlName + '-edit-row' + i }}"
							obButton="secondary"
							mat-icon-button
							(click)="onEditRow(i)"
							[disabled]="hasSelectedRows()"
							matTooltip="{{ 'i18n.app.edittable.button.edit.tooltip' | translate }}"
						>
							<mat-icon svgIcon="pen" class="ob-icon"></mat-icon>
						</button>
					</div>
					<div class="action-button" *ngIf="canRemove()">
						<button
							id="{{ controlName + '-remove-row' + i }}"
							obButton="secondary"
							mat-icon-button
							(click)="onRemoveRow(i, row)"
							[disabled]="hasSelectedRows()"
							matTooltip="{{ 'i18n.app.edittable.button.remove.tooltip' | translate }}"
						>
							<mat-icon svgIcon="trash" class="ob-icon"></mat-icon>
						</button>
					</div>
				</div>
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_HAD_ROLE">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>
				{{ "i18n.shared.qualified.relation.had.role.column" | translate }}
			</th>
			<td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" [title]="COLUMN_HAD_ROLE">
				<mat-form-field [hidden]="!isRowEditMode(i)">
					<mat-label>{{ "i18n.shared.qualified.relation.had.role.column" | translate }}</mat-label>
					<mat-select
						id="{{ controlName + '-select-hadRole' + i }}"
						formControlName="hadRole"
						(selectionChange)="type(element, $event.value, COLUMN_HAD_ROLE)"
						[compareWith]="compareType"
					>
						<mat-option
							*ngFor="let item of relationshipRole$ | async; let ii = index"
							[value]="item"
							(onSelectionChange)="type(element, item, COLUMN_HAD_ROLE)"
							id="{{ controlName + '-option-hadRole' + ii }}"
						>
							{{ item?.name | fallback: currentLanguage }}
						</mat-option>
					</mat-select>
				</mat-form-field>
				<span *ngIf="!isRowEditMode(i)">{{ element.hadRole.name | fallback: currentLanguage }}</span>
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_HREF">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>
				{{ "i18n.page.datasets.description.edit.url.column" | translate }}
			</th>
			<td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" [title]="COLUMN_HREF">
				<div formGroupName="relation">
					<mat-form-field [hidden]="!isRowEditMode(i)">
						<div class="help-input">
							<input
								id="{{ controlName + '-href' + i }}"
								formControlName="href"
								matInput
								(keyup)="type(element.relation, $event.target.value, COLUMN_HREF)"
								[value]="element.relation?.href"
								required
							/>
							<mat-icon matTooltip="{{ 'i18n.shared.helpFormatURL' | translate }}" svgIcon="help-circle" class="ob-icon help-icon"></mat-icon>
						</div>
					</mat-form-field>
				</div>
				<span *ngIf="!isRowEditMode(i)" [ngClass]="{ 'invalid-span': isControlInvalid(i) }">{{ element.relation?.href }}</span>
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_GERMAN">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>
				{{ "i18n.page.datasets.description.edit.german.language.heading.column" | translate }}
			</th>
			<td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" [title]="COLUMN_GERMAN">
				<div formGroupName="relation">
					<mat-form-field formGroupName="label" [hidden]="!isRowEditMode(i)">
						<input
							id="{{ controlName + '-de' + i }}"
							formControlName="de"
							matInput
							(keyup)="type(element.relation?.label, $event.target.value, COLUMN_GERMAN)"
							[value]="element.relation?.label?.de"
						/>
					</mat-form-field>
				</div>
				<span *ngIf="!isRowEditMode(i)">{{ element.relation?.label.de }}</span>
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_FRENCH">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>
				{{ "i18n.page.datasets.description.edit.french.language.heading.column" | translate }}
			</th>
			<td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" [title]="COLUMN_FRENCH">
				<div formGroupName="relation">
					<mat-form-field formGroupName="label" [hidden]="!isRowEditMode(i)">
						<input
							id="{{ controlName + '-fr' + i }}"
							formControlName="fr"
							matInput
							(keyup)="type(element.relation?.label, $event.target.value, COLUMN_FRENCH)"
							[value]="element.relation?.label?.fr"
						/>
					</mat-form-field>
				</div>
				<span *ngIf="!isRowEditMode(i)">{{ element.relation?.label.fr }}</span>
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_ITALIAN">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>
				{{ "i18n.page.datasets.description.edit.italian.language.heading.column" | translate }}
			</th>
			<td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" [title]="COLUMN_ITALIAN">
				<div formGroupName="relation">
					<mat-form-field formGroupName="label" [hidden]="!isRowEditMode(i)">
						<input
							id="{{ controlName + '-it' + i }}"
							formControlName="it"
							matInput
							(keyup)="type(element.relation?.label, $event.target.value, COLUMN_ITALIAN)"
							[value]="element.relation?.label?.it"
						/>
					</mat-form-field>
				</div>
				<span *ngIf="!isRowEditMode(i)">{{ element.relation?.label.it }}</span>
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_ENGLISH">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>
				{{ "i18n.page.datasets.description.edit.english.language.heading.column" | translate }}
			</th>
			<td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" [title]="COLUMN_ENGLISH">
				<div formGroupName="relation">
					<mat-form-field formGroupName="label" [hidden]="!isRowEditMode(i)">
						<input
							id="{{ controlName + '-en' + i }}"
							formControlName="en"
							matInput
							(keyup)="type(element.relation?.label, $event.target.value, COLUMN_ENGLISH)"
							[value]="element.relation?.label?.en"
						/>
					</mat-form-field>
				</div>
				<span *ngIf="!isRowEditMode(i)">{{ element.relation?.label.en }}</span>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns"></tr>
	</table>

	<div class="table-info-box">
		<ob-alert type="info" *ngIf="!datasetId">{{ "i18n.shared.qualified.qualified.attribution.alertbox.createmode" | translate }}</ob-alert>
		<app-infobox-no-content [datasource]="dataSource.data" *ngIf="datasetId"></app-infobox-no-content>
	</div>
</div>
