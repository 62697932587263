<div [formGroup]="parentForm">
	<table mat-table matSort [dataSource]="dataSource" [formArrayName]="controlName">
		<ng-container [matColumnDef]="COLUMN_ACTIONS">
			<th mat-header-cell *matHeaderCellDef>
				<div class="action-wrapper">
					<div class="action-button">
						<button
							id="{{ controlName + '-add-row' }}"
							obButton="secondary"
							mat-icon-button
							(click)="onAddRow()"
							[disabled]="!canAdd() || !datasetId"
							matTooltip="{{ 'i18n.app.edittable.button.addrow.tooltip' | translate }}"
							type="button"
						>
							<mat-icon svgIcon="plus" class="ob-icon"></mat-icon>
						</button>
					</div>
				</div>
			</th>
			<td mat-cell *matCellDef="let row; let i = index">
				<div class="action-wrapper">
					<div class="action-button" *ngIf="isRowEditMode(i)">
						<button
							id="{{ controlName + '-discard-row' + i }}"
							obButton="secondary"
							mat-icon-button
							mat-stroked-button
							(click)="onDiscard()"
							[disabled]="hasSelectedRows()"
							matTooltip="{{ 'i18n.app.edittable.button.discard.tooltip' | translate }}"
							type="button"
						>
							<mat-icon svgIcon="cancel" class="ob-icon"></mat-icon>
						</button>
					</div>
					<div class="action-button" *ngIf="isRowEditMode(i)">
						<button
							id="{{ controlName + '-save-row' + i }}"
							obButton="secondary"
							mat-icon-button
							(click)="onSave(row)"
							[disabled]="!canSave(i)"
							matTooltip="{{ 'i18n.app.edittable.button.save.tooltip' | translate }}"
							type="button"
						>
							<mat-icon svgIcon="checkmark" class="ob-icon"></mat-icon>
						</button>
					</div>
					<div class="action-button" *ngIf="canEdit()">
						<button
							id="{{ controlName + '-edit-row' + i }}"
							obButton="secondary"
							mat-icon-button
							(click)="onEditRow(i)"
							[disabled]="hasSelectedRows()"
							matTooltip="{{ 'i18n.app.edittable.button.edit.tooltip' | translate }}"
							type="button"
						>
							<mat-icon svgIcon="pen" class="ob-icon"></mat-icon>
						</button>
					</div>
					<div class="action-button" *ngIf="canRemove()">
						<button
							id="{{ controlName + '-remove-row' + i }}"
							obButton="secondary"
							mat-icon-button
							(click)="onRemoveRow(i, row)"
							[disabled]="hasSelectedRows()"
							matTooltip="{{ 'i18n.app.edittable.button.remove.tooltip' | translate }}"
							type="button"
						>
							<mat-icon svgIcon="trash" class="ob-icon"></mat-icon>
						</button>
					</div>
				</div>
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_AGENT">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>
				{{ "i18n.shared.qualified.qualified.attribution.agent.column" | translate }}
			</th>
			<td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" [title]="COLUMN_AGENT">
				<mat-form-field [hidden]="!isRowEditMode(i)">
					<mat-label>{{ "i18n.shared.qualified.qualified.attribution.agent.column" | translate }}</mat-label>
					<input
						id="{{ controlName + '-input-agent' + i }}"
						type="text"
						matInput
						[formControl]="filteredAgentsControl"
						[matAutocomplete]="auto"
						(change)="type(element, $event, COLUMN_AGENT)"
					/>
					<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayValue">
						<mat-option
							*ngFor="let agent of filteredAgents$ | async; let ii = index"
							[value]="agent"
							(onSelectionChange)="type(element, agent, COLUMN_AGENT)"
							id="{{ controlName + '-option-agent' + ii }}"
						>
							{{ agent?.name | fallback: currentLanguage }}
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>
				<span *ngIf="!isRowEditMode(i)">{{ element.agent.name | fallback: currentLanguage }}</span>
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_HAD_ROLE">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>
				{{ "i18n.shared.qualified.qualified.attribution.had.role.column" | translate }}
			</th>
			<td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" [title]="COLUMN_HAD_ROLE">
				<mat-form-field [hidden]="!isRowEditMode(i)">
					<mat-label>{{ "i18n.shared.qualified.qualified.attribution.had.role.column" | translate }}</mat-label>
					<mat-select
						id="{{ controlName + '-hadRole' }}"
						formControlName="hadRole"
						(selectionChange)="type(element, $event.value, COLUMN_HAD_ROLE)"
						[compareWith]="compareType"
					>
						<mat-option
							*ngFor="let item of attributionRole$ | async; let iii = index"
							[value]="item"
							(onSelectionChange)="type(element, item, COLUMN_HAD_ROLE)"
							id="{{ controlName + 'option-hadRole' + iii }}"
						>
							{{ item?.name | fallback: currentLanguage }}
						</mat-option>
					</mat-select>
				</mat-form-field>
				<span *ngIf="!isRowEditMode(i)">{{ element.hadRole.name | fallback: currentLanguage }}</span>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns"></tr>
	</table>

	<div class="table-info-box">
		<ob-alert type="info" *ngIf="!datasetId">{{ "i18n.shared.qualified.relation.alertbox.createmode" | translate }}</ob-alert>
		<app-infobox-no-content [datasource]="dataSource.data" *ngIf="datasetId"></app-infobox-no-content>
	</div>
</div>
