import {Inject, Injectable, InjectionToken, OnDestroy, Optional} from '@angular/core';
import {AuthConfig, OAuthService} from 'angular-oauth2-oidc';
import {ReplaySubject, Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {jwtDecode} from 'jwt-decode';
import {NGXLogger} from 'ngx-logger';

export interface IResourceAccessRoles {
	name: string;
	roles: string[];
}

export const KEYCLOAK_URL = new InjectionToken<string>('KEYCLOAK_URL');
export const KEYCLOAK_CLIENT_ID = new InjectionToken<string>('KEYCLOAK_CLIENT_ID');

@Injectable({
	providedIn: 'root'
})
export class SessionService implements OnDestroy {
	authConfig: AuthConfig = {
		// URL of the SPA to redirect the user to after login
		redirectUri: `${window.location.origin}/login`,
		postLogoutRedirectUri: `${window.location.origin}/home`,
		logoutUrl: `${window.location.origin}/logout`,

		// The SPA's id. The SPA is registerd with this id at the auth-server
		// clientId: 'server.code',
		clientId: 'BFS.SIS',

		// Just needed if your auth server demands a secret. In general, this
		// is a sign that the auth server is not configured with SPAs in mind
		// and it might not enforce further best practices vital for security
		// such applications.
		// dummyClientSecret: 'secret',

		responseType: 'code',

		// set the scope for the permissions the client should request
		// The first four are defined by OIDC.
		// Important: Request offline_access to get a refresh token
		// The api scope is a usecase specific one
		scope: 'openid offline_access roles',

		showDebugInformation: false,
		requireHttps: false
	};

	public isAuthenticated$: Observable<boolean> = new Observable();
	public dcatRoles$: Observable<string[]>;

	private readonly dcatRolesSubject: ReplaySubject<string[]> = new ReplaySubject<string[]>();
	private readonly unsubscribe: Subject<any> = new Subject();

	private readonly KEYCLOAK_DCAT_CLIENT = 'BFS.SIS.DCAT';
	private readonly EIAM_DCAT_PREFIX = 'BFS-i14y.dcat_';

	constructor(
		private readonly logger: NGXLogger,
		private readonly oAuthService: OAuthService,
		@Optional() @Inject(KEYCLOAK_URL) keycloakUrl?: string,
		@Optional() @Inject(KEYCLOAK_CLIENT_ID) keycloakClientId?: string
	) {
		this.dcatRoles$ = this.dcatRolesSubject.asObservable();
		if (keycloakClientId) {
			this.authConfig.clientId = keycloakClientId;
		}
		this.oAuthService.events
			.pipe(
				map(() => {
					if (this.isAuthenticated()) {
						this.dcatRolesSubject.next(this.getDcatRoles());
					}
				}),
				takeUntil(this.unsubscribe)
			)
			.subscribe();

		this.isAuthenticated$ = this.oAuthService.events.pipe(map(() => this.isAuthenticated()));

		if (keycloakUrl) {
			this.configure(keycloakUrl);
		}
	}

	ngOnDestroy(): void {
		this.unsubscribe.next(undefined);
		this.unsubscribe.complete();
	}

	/**
	 * To log in with CodeFlow method in keycloak. User is redierected to keycloak server and, if login is correct, directed back to previous address.
	 */
	public login() {
		this.oAuthService.initCodeFlow();
	}

	/**
	 * To log out the user.
	 */
	public logout() {
		this.oAuthService.logOut();
	}

	/**
	 * @returns true, if the user is signed in or not.
	 */
	public isAuthenticated() {
		return this.oAuthService.hasValidIdToken() && this.oAuthService.hasValidAccessToken();
	}

	/**
	 * Get a property of the current token
	 * @param propertyName
	 * @returns The content of the token's property
	 */
	public getTokenProperty<Type>(propertyName: string): Type {
		const token = jwtDecode<any>(this.oAuthService.getAccessToken());
		return token[propertyName] as Type;
	}

	public getTokenProperties<Type>(): Type {
		return jwtDecode<any>(this.oAuthService.getAccessToken());
	}

	public getTokenPropertyAsObservable<Type>(arg: string): Observable<Type> {
		return this.isAuthenticated$.pipe(
			map(x => {
				if (x) {
					const token = jwtDecode<any>(this.oAuthService.getAccessToken());
					return token[arg] as Type;
				}
				return null as unknown as Type;
			})
		);
	}

	public getTokenPropertiesAsObservable<Type>(): Observable<Type> {
		return this.isAuthenticated$.pipe(
			map(x => {
				if (x) {
					const token = jwtDecode<any>(this.oAuthService.getAccessToken());
					return token as Type;
				}
				return null as unknown as Type;
			})
		);
	}

	private configure(issuer: string) {
		this.authConfig.issuer = issuer;
		this.oAuthService.configure(this.authConfig);
		this.oAuthService.loadDiscoveryDocumentAndTryLogin();
		this.oAuthService.setupAutomaticSilentRefresh();
	}

	private getDcatRoles(): string[] {
		const keycloakRoles = jwtDecode<any>(this.oAuthService.getAccessToken()).resource_access ?? {};
		const kcRoles = Object.keys(keycloakRoles)
			.filter(key => key === this.KEYCLOAK_DCAT_CLIENT)
			.reduce((roles, key) => {
				return {...roles, ...keycloakRoles[key].roles};
			}, {});
		const eiamRoles = (jwtDecode<any>(this.oAuthService.getAccessToken()).role as string[]) ?? [];
		const eRoles = eiamRoles.filter(r => r.includes(this.EIAM_DCAT_PREFIX)).map(x => x.split(this.EIAM_DCAT_PREFIX).pop() as string);
		return [...(Object.values(kcRoles) as string[]), ...eRoles];
	}
}
