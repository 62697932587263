import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {CodelistInputClient, MultiLanguage} from '@bfs-sis/bfs-iop-admin-web-api-client';
import {createMultilangValidator} from '../../validators/multilang.validator';
import {Observable, of} from 'rxjs';
import {createWhitespaceValidator} from '../../validators/whitespace.validator';
import {CodelistEntryCodeValidator} from '../../validators/codelistentry-code.validator';
import {CodelistEntryParentCodeValidator} from '../../validators/codelistentry-parentcode.validator';

@Component({
	selector: 'app-modal-dialog-codelist',
	templateUrl: './modal-dialog.component.html'
})
export class ModalDialogCodeListComponent implements OnInit {
	form!: UntypedFormGroup;

	searchCodeListEntries$!: Observable<string[]>;

	private selectedParentCodeValue: string | undefined = undefined;

	constructor(
		private readonly codelistInputClient: CodelistInputClient,
		private readonly codelistEntryCodeValidator: CodelistEntryCodeValidator,
		private readonly codelistEntryParentCodeValidator: CodelistEntryParentCodeValidator,
		private readonly dialogRef: MatDialogRef<ModalDialogCodeListComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit(): void {
		this.form = new UntypedFormGroup({
			value: new UntypedFormControl('', {
				validators: [Validators.required, createWhitespaceValidator()],
				// eslint-disable-next-line max-len
				asyncValidators: [this.codelistEntryCodeValidator.validate.bind(this.codelistEntryCodeValidator)],
				updateOn: 'blur'
			}),

			parentCode: new UntypedFormControl('', {
				asyncValidators: [this.codelistEntryParentCodeValidator.validate.bind(this.codelistEntryParentCodeValidator)],
				updateOn: 'blur'
			}),
			name: new UntypedFormGroup(
				this.getObjectFromKeys(this.data.contentLanguages, () => new UntypedFormControl()),
				{
					validators: [createMultilangValidator([...this.data.contentLanguages])]
				}
			),
			description: new UntypedFormGroup(this.getObjectFromKeys(this.data.contentLanguages, () => new UntypedFormControl()))
		});

		this.mapDataToForm();

		if (this.data.codeListEntry.value) {
			this.codelistEntryCodeValidator.setInitalValue(this.data.codeListEntry.value);
		}

		this.codelistEntryCodeValidator.codeListId = this.data.codeListEntry.codelistId;
		this.codelistEntryParentCodeValidator.codeListId = this.data.codeListEntry.codelistId;
	}

	get isSaveDisabled(): boolean {
		return !this.form.dirty;
	}

	onParentCodeInputChanged(query: string): void {
		if (this.data.codeListEntry.codelistId && query.length > 0) {
			// eslint-disable-next-line max-len
			this.codelistInputClient.getCodelistEntriesSearchByIdAndSearchStringAndMaxCount(this.data.codeListEntry.codelistId, query, 20).subscribe(response => {
				this.searchCodeListEntries$ = of(response.result.map(entry => entry.value ?? ''));
			});
		}
	}

	onParentCodeSelection(parentCodeValue: string) {
		this.selectedParentCodeValue = parentCodeValue;
	}

	save() {
		this.mapFormToData();
		this.dialogRef.close(this.data);
	}

	private getObjectFromKeys<Type>(keys: readonly string[], initialValue: (key: string) => Type) {
		return Object.assign({}, ...keys.map(x => ({[x]: initialValue(x)})));
	}

	private mapDataToForm(): void {
		this.form.patchValue({
			value: this.data.codeListEntry.value,
			parentCode: this.data.codeListEntry.parentCode,
			name: new MultiLanguage(this.data.codeListEntry.name),
			description: this.data.codeListEntry.description
		});
	}

	private mapFormToData(): void {
		this.data.codeListEntry.value = this.form.value.value;
		this.data.codeListEntry.parentCode = this.selectedParentCodeValue;
		this.data.contentLanguages.forEach((l: string) => {
			this.data.codeListEntry.name![l as keyof MultiLanguage] = this.form.value.name[l] || undefined;
			this.data.codeListEntry.description![l as keyof MultiLanguage] = this.form.value.description[l] || undefined;
		});
	}
}
