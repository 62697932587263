<ob-alert type="warning" *ngIf="showWarning">
	{{ warningText }}
</ob-alert>
<ob-alert type="info" *ngIf="showInfo">
	{{ infoText }}
</ob-alert>

<div class="status-container" *ngIf="registrationStatus?.status || registrationStatus?.proposal">
	<div class="status-block" *ngIf="enableVersioning">
		<div class="status-title">
			<div>{{ "i18n.page.datasets.description.edit.version" | translate }}</div>
		</div>
		<div class="status-content">
			<div class="status-wrapper">
				<div class="status-label">{{ "i18n.shared.status.current" | translate }}</div>
				<div class="status-value">
					<div>{{ version ?? "-" }}</div>
				</div>
			</div>
		</div>
		<div class="status-actions">
			<button
				id="create-newversion"
				type="button"
				mat-button
				obButton="secondary"
				[routerLink]="['newversion']"
				[disabled]="disbaleNewVersion$ | async"
				id="newversion"
			>
				{{ "i18n.page.dataset.button.create.newversion" | translate }}
			</button>
		</div>
	</div>

	<div class="status-block">
		<div class="status-title">
			<div>{{ "i18n.shared.status.registrationstatus.title" | translate }}</div>
		</div>
		<div class="status-content">
			<div class="status-wrapper" *ngIf="registrationStatus?.status">
				<div class="status-label">{{ "i18n.shared.status.current" | translate }}</div>
				<div class="status-value">
					<div>
						<mat-chip-set>
							<mat-chip [class]="registrationStatus?.status | registrationstatusclass">{{
								"i18n.shared.status.registrationstatus." + registrationStatus?.status?.toLowerCase()?.replace(" ", "") | translate
							}}</mat-chip>
						</mat-chip-set>
					</div>
				</div>
			</div>
			<div class="status-actions">
				<button
					id="allowedstatus-button"
					*ngIf="showDropdownButton(combinedStatus)"
					mat-icon-button
					obButton="secondary"
					#setRegStatMenuTrigger="matMenuTrigger"
					[matMenuTriggerFor]="setRegStatMenu"
				>
					<mat-icon svgIcon="{{ setRegStatMenuTrigger.menuOpen ? 'chevron-up' : 'chevron-down' }}"></mat-icon>
				</button>
				<mat-menu #setRegStatMenu="matMenu">
					<button
						mat-icon-button
						class="menu-button"
						*ngFor="let status of combinedStatus"
						id="allowedStatus-{{ status }}"
						(click)="isStatusAllowed(status) ? onSetRegistrationStatusClick(status) : onProposeRegistrationStatusClick(status)"
					>
						{{ "i18n.shared.status.registrationstatus." + status.toLowerCase().replace(" ", "") | translate }}
					</button>
				</mat-menu>
			</div>

			<div class="status-wrapper" *ngIf="registrationStatus?.proposal">
				<div class="status-label">{{ "i18n.shared.status.proposed" | translate }}</div>
				<div class="status-value">
					<div>
						<mat-chip-set>
							<mat-chip [class]="registrationStatus?.proposal | registrationstatusclass">{{
								"i18n.shared.status.registrationstatus." + registrationStatus?.proposal?.toLowerCase()?.replace(" ", "") | translate
							}}</mat-chip>
						</mat-chip-set>
					</div>
				</div>
			</div>
			<div class="status-actions">
				<ng-container>
					<button
						id="allowedStatusProposal-button"
						*ngIf="registrationStatus?.proposal"
						mat-icon-button
						obButton="secondary"
						#propRegStatMenuTrigger="matMenuTrigger"
						[matMenuTriggerFor]="propRegStatMenu"
					>
						<mat-icon svgIcon="{{ propRegStatMenuTrigger.menuOpen ? 'chevron-up' : 'chevron-down' }}"></mat-icon>
					</button>
					<mat-menu #propRegStatMenu="matMenu">
						<button
							[hidden]="i === 0 && !isStatusProposalAllowed()"
							mat-icon-button
							class="menu-button"
							*ngFor="let item of menuItemsRegistrationStatus; let i = index"
							id="allowedstatusProposals-{{ item.text }}"
							(click)="item.action ? onProposeRegistrationStatusCancel() : onSetRegistrationStatusClick(registrationStatus!.proposal!)"
						>
							<mat-icon [svgIcon]="item.icon"></mat-icon>
							{{ item.text | translate }}
						</button>
					</mat-menu>
				</ng-container>
			</div>
		</div>
	</div>

	<div class="status-block" *ngIf="publicationLevel?.level || publicationLevel?.proposal">
		<div class="status-title">
			<div>{{ "i18n.shared.status.publicationlevel.title" | translate }}</div>
		</div>
		<div class="status-content">
			<div class="status-wrapper" *ngIf="publicationLevel?.level">
				<div class="status-label">{{ "i18n.shared.status.current" | translate }}</div>
				<div class="status-value">
					<div>
						<mat-chip-set>
							<mat-chip [class]="publicationLevel?.level | publicationlevelclass">{{
								"i18n.shared.status.publicationlevel." + publicationLevel?.level?.toLowerCase()?.replace(" ", "") | translate
							}}</mat-chip>
						</mat-chip-set>
					</div>
				</div>
			</div>
			<div class="status-actions">
				<button
					id="allowedLevels-button"
					*ngIf="showDropdownButton(combinedLevel)"
					mat-icon-button
					obButton="secondary"
					#setPubLevelMenuTrigger="matMenuTrigger"
					[matMenuTriggerFor]="setPubLevelMenu"
				>
					<mat-icon svgIcon="{{ setPubLevelMenuTrigger.menuOpen ? 'chevron-up' : 'chevron-down' }}"></mat-icon>
				</button>
				<mat-menu #setPubLevelMenu="matMenu">
					<button
						mat-icon-button
						class="menu-button"
						*ngFor="let publication of combinedLevel"
						id="allowedLevels-{{ publication }}"
						(click)="isSetLevelAllowed() ? onSetPublicationLevelClick(publication) : onProposePublicationLevelClick(publication)"
					>
						{{ "i18n.shared.status.publicationlevel." + publication.toLowerCase().replace(" ", "") | translate }}
					</button>
				</mat-menu>
			</div>
			<div class="status-wrapper" *ngIf="publicationLevel?.proposal">
				<div class="status-label">{{ "i18n.shared.status.proposed" | translate }}</div>
				<div class="status-value">
					<div>
						<mat-chip-set>
							<mat-chip [class]="publicationLevel?.proposal | publicationlevelclass">{{
								"i18n.shared.status.publicationlevel." + publicationLevel?.proposal?.toLowerCase()?.replace(" ", "") | translate
							}}</mat-chip>
						</mat-chip-set>
					</div>
				</div>
			</div>
		</div>
		<div class="status-actions">
			<button
				id="allowedProposalLevel-button"
				*ngIf="isSetLevelAllowed()"
				mat-icon-button
				obButton="secondary"
				#propPubLevelMenuTrigger="matMenuTrigger"
				[matMenuTriggerFor]="propPubLevelMenu"
			>
				<mat-icon svgIcon="{{ propPubLevelMenuTrigger.menuOpen ? 'chevron-up' : 'chevron-down' }}"></mat-icon>
			</button>
			<mat-menu #propPubLevelMenu="matMenu">
				<button
					id="allowedProposalLevel-button"
					mat-icon-button
					class="menu-button"
					*ngFor="let item of menuItemsPublicationLevel"
					id="allowedLevelProposals-{{ item }}"
					(click)="onSetPublicationLevelClick(publicationLevel!.proposal!)"
				>
					<mat-icon [svgIcon]="item.icon"></mat-icon>
					{{ item.text | translate }}
				</button>
			</mat-menu>
		</div>
		<a *ngIf="publicationLevel?.level === 'I14Y Public'" href="{{ redirectToExternalResource() }}" mat-button obButton="tertiary">{{
			"i18n.shared.status.publicationlevel.link.title" | translate
		}}</a>
	</div>

	<div class="floating-lock-icon" *ngIf="enableLock">
		<div>
			<mat-slide-toggle (change)="onChangeLockToggle($event)" [(ngModel)]="isLocked" [disabled]="disableLock">{{
				"i18n.shared.status.lock.toggel" | translate
			}}</mat-slide-toggle>
		</div>
	</div>
</div>
