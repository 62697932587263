import {Component, Input, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MultiLanguage} from '@bfs-sis/bfs-iop-admin-web-api-client';
import {TranslateService} from '@ngx-translate/core';
import {ApplicationLanguage} from '../ApplicationLanguage.enum';

@Component({
	selector: 'app-sortable-list-view',
	template: ''
})
export abstract class SortableListViewComponent<T extends {}, K extends keyof T> {
	@Input()
	set data(data: T[]) {
		if (data) {
			this.dataSource.data = data;
		}
	}
	get data(): T[] {
		return this.dataSource.data;
	}

	@Input('paginator')
	set outerPaginator(paginator: MatPaginator) {
		this.setPaginator(paginator);
	}

	@ViewChild(MatPaginator)
	set matPaginator(paginator: MatPaginator) {
		this.setPaginator(paginator);
	}

	@ViewChild(MatSort)
	set matSort(sort: MatSort) {
		this.sort = sort;
		this.dataSource.sort = this.sort;
	}

	readonly dataSource: MatTableDataSource<T>;
	abstract displayedColumns: string[];

	private paginator!: MatPaginator;
	private sort!: MatSort;
	private readonly SORT_DIRECTION_ASCENDING = 'asc';
	private readonly SORT_DIRECTION_DESCENDING = 'desc';

	constructor(readonly translate: TranslateService) {
		this.dataSource = new MatTableDataSource<T>([]);
	}

	public sortData(sort: Sort) {
		const sortColumn = sort.active as K;
		const sortDirection = sort.direction;
		const sortedData = [...this.data].sort((left: T, right: T) => {
			switch (sortDirection) {
				case this.SORT_DIRECTION_DESCENDING:
					return this.sortDescending(left, right, sortColumn);
				case this.SORT_DIRECTION_ASCENDING:
				default:
					return this.sortAscending(left, right, sortColumn);
			}
		});
		this.dataSource.data = sortedData;
	}

	protected compareReturn<TCompare = number | string | T[K]>(left: TCompare, right: TCompare): number {
		if (left < right) {
			return -1;
		}

		if (left === right) {
			return 0;
		}

		return 1;
	}

	protected compareStringReturn(left: string, right: string): number {
		const collator = new Intl.Collator();
		return collator.compare(left, right);
	}

	protected compareMultiLanguageReturn(left: MultiLanguage, right: MultiLanguage): number {
		switch (this.translate.currentLang) {
			case ApplicationLanguage.en:
				return this.compareReturn(left?.en, right?.en);
			case ApplicationLanguage.fr:
				return this.compareReturn(left?.fr, right?.fr);
			case ApplicationLanguage.it:
				return this.compareReturn(left?.it, right?.it);
			case ApplicationLanguage.de:
			default:
				return this.compareReturn(left?.de, right?.de);
		}
	}

	protected compareMultiLanguageArrayReturn(left: MultiLanguage[], right: MultiLanguage[]): number {
		switch (this.translate.currentLang) {
			case this.translate.currentLang:
			case ApplicationLanguage.en:
				return this.compareReturn(left[0]?.en, right[0]?.en);
			case ApplicationLanguage.fr:
				return this.compareReturn(left[0]?.fr, right[0]?.fr);
			case ApplicationLanguage.it:
				return this.compareReturn(left[0]?.it, right[0]?.it);
			case ApplicationLanguage.de:
			default:
				return this.compareReturn(left[0]?.de, right[0]?.de);
		}
	}

	private setPaginator(paginator: MatPaginator) {
		if (paginator) {
			this.paginator = paginator;
			this.dataSource.paginator = this.paginator;
		}
	}

	private sortAscending(left: T, right: T, sortColumn: K): number {
		return this.compareObjects(left, right, sortColumn);
	}

	private sortDescending(left: T, right: T, sortColumn: K): number {
		const newLeft = right;
		const newRight = left;

		return this.compareObjects(newLeft, newRight, sortColumn);
	}

	private compareObjects(left: T, right: T, key: K): number {
		const leftValue = left[key];
		const rightValue = right[key];

		if (typeof leftValue === 'string' && typeof rightValue === 'string') {
			return this.compareStringObject(leftValue, rightValue);
		}

		if (typeof leftValue === 'number' && typeof rightValue === 'number') {
			return this.compareReturn(leftValue, rightValue);
		}

		if (typeof leftValue === 'boolean' && typeof rightValue === 'boolean') {
			return this.compareReturn(leftValue, rightValue);
		}

		if (Array.isArray(leftValue) && typeof leftValue[0] === 'string' && Array.isArray(rightValue) && typeof rightValue[0] === 'string') {
			return this.compareStringObject(leftValue.join(','), rightValue.join(','));
		}

		if (Array.isArray(leftValue) && leftValue[0] instanceof MultiLanguage && Array.isArray(rightValue) && rightValue[0] instanceof MultiLanguage) {
			return this.compareMultiLanguageArrayReturn(leftValue, rightValue);
		}

		if (Array.isArray(leftValue) && Array.isArray(rightValue)) {
			return this.compareReturn(leftValue.length, rightValue.length);
		}

		if (leftValue instanceof MultiLanguage && rightValue instanceof MultiLanguage) {
			return this.compareMultiLanguageReturn(leftValue, rightValue);
		}

		return this.compareReturn(leftValue, rightValue);
	}

	private compareStringObject(leftValue: string, rightValue: string): number {
		if (!isNaN(Number(leftValue)) && !isNaN(Number(rightValue))) {
			return this.compareReturn(Number(leftValue), Number(rightValue));
		}

		if (!isNaN(Date.parse(leftValue)) && !isNaN(Date.parse(rightValue))) {
			return this.compareReturn(Date.parse(leftValue), Date.parse(rightValue));
		}

		return this.compareStringReturn(leftValue, rightValue);
	}
}
